import {
  Author,
  Collection,
  CollectionWithPosts,
  creatableAuthorFields,
  IntegrationServer,
  updatableAuthorFields,
} from "client-server-shared/types/types";
import { SearchPageItem } from "client-server-shared/types/search";
import { ImageItem } from "client-server-shared/types/image-result";
import { apiClient, fetchClient } from "./client-axios";
import { GenerateOptions } from "templates/shared-templates/types";
import { ensureSequence } from "client-server-shared/ensure-chat-message-sequence";
import { omit, pick } from "client-server-shared/utils/lodash-methods";
import { Book } from "client-server-shared/types/book";
import { Webpage } from "client-server-shared/types/webpage";
import { Video } from "client-server-shared/types/video";
import { ArticleToLinkTo } from "client-server-shared/types/page-booster";
import {
  BackendEmailKey,
  EmailStatusByIdInBackendFormat,
} from "server/emails/constants";
import { JournalData } from "client-server-shared/types/journal";
import { Features } from "client-server-shared/utils/check-subscription";
import { Style } from "util";
import { getURL } from "client-server-shared/utils/get-url";
import { GenerateImageParams, Prompt } from "client-server-shared/types";
import {
  BulkGenerateJobConfigPayload,
  Workflow,
  WorkflowJobConfigPayload,
  SchedulePublishConfig,
} from "client-server-shared/types/workflow";
import { EnhanceContext } from "client-server-shared/types/enhance-text";
import { CreativityLevel } from "client-server-shared/config/template-config";
import {
  FromType,
  IntegrationType,
  imageSizes,
  apiAppUrl,
} from "client-server-shared/constants";
import { ConnectedWebsitesResponse } from "client-server-shared/types/connected-website";
import { Notification } from "client-server-shared/types/notifications";
import { KeywordResult } from "client-server-shared/types/keywords";
import { RewriteType } from "server/cron/types";
import { trackEditorInteractiveCommand } from "utils/tracking/common";
import type { Folder } from "@prisma/client";
import { ArticleImproverPayload } from "client-server-shared/types/article-improver";

export const inMobileApp = () =>
  typeof window !== "undefined" && (window as any).inMobileApp === true;

const getCollectionById = async (id: string) => {
  return (await apiClient.get(`/api/collections/${id}`)).data.data;
};

const cancelPostSchedule = async (postId: string) => {
  return await apiClient.post(`/api/posts/${postId}/cancel-publish`);
};
const getCollections = async (
  offset: number,
  limit: number,
  folderId?: string,
  userAdmin?: boolean
) => {
  try {
    return (
      await apiClient.get(
        `${apiAppUrl}/api/collections?offset=${offset}&limit=${limit}${
          folderId ? `&folderId=${folderId}` : ""
        }`
      )
    ).data;
  } catch (e) {
    return (
      await apiClient.get(
        `/api/collections?offset=${offset}&limit=${limit}${
          folderId ? `&folderId=${folderId}` : ""
        }`
      )
    ).data;
  }

  /*
  if (userAdmin) {
    try {
      return (
        await apiClient.get(
          `${apiAppUrl}/api/collections?offset=${offset}&limit=${limit}${
            folderId ? `&folderId=${folderId}` : ""
          }`
        )
      ).data;
    } catch (e) {
      return (
        await apiClient.get(
          `/api/collections?offset=${offset}&limit=${limit}${
            folderId ? `&folderId=${folderId}` : ""
          }`
        )
      ).data;
    }
  } else {
    return (
      await apiClient.get(
        `/api/collections?offset=${offset}&limit=${limit}${
          folderId ? `&folderId=${folderId}` : ""
        }`
      )
    ).data;
  }
*/
  /*
  try {
    return (
      await apiClient.get(
        `${apiAppUrl}/api/collections?offset=${offset}&limit=${limit}${
          folderId ? `&folderId=${folderId}` : ""
        }`
      )
    ).data;
  } catch (e) {
    return (
      await apiClient.get(
        `/api/collections?offset=${offset}&limit=${limit}${
          folderId ? `&folderId=${folderId}` : ""
        }`
      )
    ).data;
  }
  */
};

const getAuthors = async () => {
  return (await apiClient.get(`/api/authors`)).data.data;
};

const getUserCurrentTeam = async () => {
  return (await apiClient.get(`/api/team`)).data.data;
};

const removeUserFromTeam = async (teamId: string, userId: string) => {
  return await apiClient.delete(`/api/team/${teamId}/${userId}`);
};

const inviteUserToTeam = async (teamId: string, userEmail: string) => {
  return await apiClient.post(`/api/team/${teamId}`, {
    email: userEmail,
  });
};

const getCommonData = async () => {
  return (await apiClient.get(`/api/common`)).data.data;
};

const updateAuthorById = async (author: Author & { id: string }) => {
  return apiClient.patch(`/api/authors/${author.id}`, {
    ...pick(author, updatableAuthorFields),
  });
};
const createAuthor = async (author: Author) => {
  return (
    await apiClient.post(`/api/authors`, {
      ...pick(author, creatableAuthorFields),
    })
  ).data.data;
};

const deleteAuthorById = async (authorId: string) => {
  return await apiClient.delete(`/api/authors/${authorId}`);
};

const saveSettings = async ({ categories }: { categories: string[] }) => {
  return await apiClient.put(`/api/common/settings`, {
    categories: categories,
  });
};

const saveCollectionById = async (collection: Collection) => {
  return (
    await apiClient.post(`/api/collections`, {
      ...collection,
    })
  ).data.data;
};

const updateCollectionById = async (id: string, collection: Collection) => {
  return (
    await apiClient.put(`/api/collections/${id}`, {
      ...collection,
    })
  ).data.data;
};

const getPresignedUrl = async ({
  fileName,
  fileType,
}: {
  fileName: string;
  fileType: string;
}) => {
  return (
    await apiClient.post(`/api/images/presigned-url`, {
      fileName,
      fileType,
    })
  ).data.data;
};

const uploadImageByFile = async (url: string, formData: FormData) => {
  return await apiClient.post(url, formData);
};

const publishToGhost = async ({
  postId,
  status,
  integrationId,
  existingPostId,
  author,
  tags,
  schedulePublishConfig,
  metaTitle,
  metaDescription,
}: {
  categories?: string[];
  author?: string;
  existingPostId?: string;
  integrationId: string;
  postId: string;
  status: string;
  tags?: string[];
  schedulePublishConfig?: SchedulePublishConfig;
  metaTitle?: string;
  metaDescription?: string;
}) => {
  return (
    await apiClient.post(`/api/integrations/publish`, {
      postId,
      status,
      author,
      integrationId,
      existingPostId,
      tags,
      schedulePublishConfig,
      metaTitle,
      metaDescription,
      type: IntegrationType.Ghost,
    })
  ).data.data;
};

const publishToWordPress = async ({
  postId,
  status,
  integrationId,
  existingPostId,
  author,
  categories,
  tags,
  schedulePublishConfig,
  metaTitle,
  metaDescription,
}: {
  categories?: string[];
  author?: string;
  existingPostId?: string;
  integrationId: string;
  postId: string;
  status: string;
  tags?: string[];
  schedulePublishConfig?: SchedulePublishConfig;
  metaTitle?: string;
  metaDescription?: string;
}) => {
  return (
    await apiClient.post(`/api/integrations/publish`, {
      postId,
      status,
      author,
      integrationId,
      existingPostId,
      categories,
      tags,
      schedulePublishConfig,
      metaTitle,
      metaDescription,
      type: IntegrationType.WordPress,
    })
  ).data.data;
};

const publishToMedium = async ({
  content,
  tags,
  mode,
  postTitle,
  integrationId,
  postId,
  schedulePublishConfig,
}: {
  integrationId: string;
  content: string;
  tags: string[];
  mode: string;
  postTitle: string;
  postId?: string;
  schedulePublishConfig?: SchedulePublishConfig;
}) => {
  return (
    await apiClient.post(`/api/integrations/publish`, {
      content,
      tags,
      mode,
      type: "medium",
      postTitle,
      integrationId,
      postId,
      schedulePublishConfig,
    })
  ).data.data.url;
};

const publishToReddit = async (params) => {
  return (
    await apiClient.post(`/api/integrations/publish`, {
      ...params,
      type: "reddit",
    })
  ).data.data.url;
};

const publishToWebflow = async (params) => {
  return (
    await apiClient.post(`/api/integrations/publish`, {
      ...params,
      type: IntegrationType.Webflow,
    })
  ).data.data;
};

const importFromWordPress = async (
  slug: string,
  integrationId: string
): Promise<{
  content: string;
  title: string;
  slug: string;
  id: string;
}> => {
  return (
    await apiClient.post(`/api/integrations/import`, {
      slug,
      integrationId,
      type: "wordpress",
    })
  ).data.data;
};

const stripeCheckout = async ({
  priceId,
  quantity,
  clientReferenceId,
  freeTrial,
}: {
  clientReferenceId?: string;
  priceId: string;
  quantity: number;
  freeTrial?: boolean;
}) => {
  return (
    await apiClient.post(`/api/checkout/stripe`, {
      priceId,
      quantity,
      clientReferenceId,
      freeTrial,
      returnUrl: inMobileApp() ? getURL("/rn") : undefined,
    })
  ).data.data;
};

const redirectToCustomerProtal = async (returnUrl: string) => {
  return (
    await apiClient.post(`/api/checkout/stripe-portal`, {
      returnUrl: inMobileApp() ? getURL("/rn") : undefined,
    })
  ).data.data;
};

const updateUserById = async (
  userId: string,
  update: Partial<
    EmailStatusByIdInBackendFormat & {
      name?: string;
      features?: Features;
      onboardingOption?: string;
      medium_access_token?: string;
      shopify_url?: string;
      shopify_access_token?: string;
      shopify_secret_key?: string;
      locale?: string;
      location?: string;
      website_url?: string;
    }
  >
) => {
  const response = await apiClient.patch(`/api/users/${userId}`, update);
  return response.data.data;
};

const updateUserPassword = async (password) => {
  const response = await apiClient.post(`/api/auth/change-password`, {
    password,
  });
  return response.data;
};

const updateContentfulAccessToken = async (userId: string, token: string) => {
  await apiClient.post(`/api/users/${userId}/contentful-auth`, {
    access_token: token,
  });
};

const deleteCollectionById = async (collectionId: string) => {
  await apiClient.delete(`/api/collections/${collectionId}`);
};

const deleteCollectionByIds = async (ids: string[]) => {
  await apiClient.post(`/api/collections/delete`, {
    ids: ids,
  });
};

const deletePostById = async (postId: string) => {
  await apiClient.delete(`/api/posts/${postId}`);
};

const onRestoreImage = async (path: string) => {
  return (
    await apiClient.post(`/api/images/restore`, {
      path,
    })
  ).data.data;
};

const onSendMessageToChat = async ({
  messages,
  realTime,
  sourceId,
  features,
  style,
  sourceIds,
  persona,
  creativityLevel,
  token,
}: {
  token: string;
  realTime: boolean;
  sourceId?: string;
  messages: any[];
  features?: Features;
  persona?: string;
  style?: string;
  sourceIds?: string[];
  creativityLevel: CreativityLevel;
}) => {
  return fetchClient.post(
    !realTime || sourceId ? "/api/ai-chat" : "/api/realtime-chat",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        messages: ensureSequence(messages).slice(-15),
        sourceId,
        features,
        style,
        sourceIds,
        persona,
        creativityLevel,
      }),
    }
  );
};

const onDocSearch = async ({
  messages,
  debug,
}: {
  messages: any[];
  debug: boolean;
}) => {
  return await fetchClient.post(`/api/doc-search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      messages,
      ...(debug ? { debug: true } : {}),
    }),
  });
};

const onGenerateArtImage = async (config: any) => {
  return (
    await apiClient.post("/api/image/generate", {
      ...config,
    })
  ).data.data;
};
const onGenerateArtImageVariations = async (config: any) => {
  return (
    await apiClient.post("/api/image/variation", {
      ...config,
    })
  ).data.data;
};

const generateImageBaseOnContext = async ({
  selectedText,
  language,
  size,
}: {
  selectedText: string;
  language?: string;
  size?: string;
}): Promise<{ url: string; path: string; alt: string }[]> => {
  return (
    await apiClient.post("/api/image/generate", {
      contextText: selectedText,
      output: 1,
      size: size || imageSizes[0],
      language,
    })
  ).data.data;
};

const suggestArticlesToLinkTo = async (params: {
  urls: string[];
  maxNumberOfArticles: number;
  integration: IntegrationServer | null;
}): Promise<ArticleToLinkTo[]> => {
  return (await apiClient.post(`/api/page-booster/suggest`, params)).data.data;
};

const generateLinksForArticles = async (params: {
  articlesToAdd: ArticleToLinkTo[];
  autoPublish: boolean;
  integration: IntegrationServer | null;
}): Promise<void> => {
  return (await apiClient.post(`/api/page-booster/generate`, params)).data.data;
};

const getUserChats = async (offset: number, limit: number) => {
  return (
    await apiClient.get(`/api/chat/completions?offset=${offset}&limit=${limit}`)
  ).data;
};

const getUserWorkflows = async (
  offset: number,
  limit: number
): Promise<{
  data: Workflow[];
  hasMore: boolean;
}> => {
  return (await apiClient.get(`/api/workflows?offset=${offset}&limit=${limit}`))
    .data;
};
const getUserWorkflowById = async (id: string) => {
  return (await apiClient.get(`/api/workflows/${id}`)).data.data;
};

const deleteUserWorkflow = async (id: string): Promise<void> => {
  return await apiClient.delete(`/api/workflows/${id}`);
};

const condensedText = async (body: {
  content?: string;
  urls?: string[];
  language?: string;
}) => {
  return (
    await apiClient.post(`/api/tools`, {
      ...body,
      type: "condensed-text",
    })
  ).data.data;
};

const getFullChatByChatId = async (chatId: string) => {
  return (await apiClient.get(`/api/chat/${chatId}`)).data.data;
};

const deleteChatByChatId = async (chatId: string) => {
  return await apiClient.delete(`/api/chat/${chatId}`);
};

const createChat = async (
  messages: any[],
  id?: string,
  withEmbedding?: boolean,
  firstTimeChattingWithFile?: boolean
) => {
  return (
    await apiClient.post(`/api/chat/completions`, {
      id,
      withEmbedding,
      messages,
      firstTimeChatWithFile: firstTimeChattingWithFile,
    })
  ).data.data;
};

const addMessageToChat = async (
  chatId: string,
  messages: any[],
  withEmbedding?: boolean,
  firstTimeChattingWithFile?: boolean
) => {
  return (
    await apiClient.put(`/api/chat/${chatId}`, {
      withEmbedding,
      messages,
      firstTimeChatWithFile: firstTimeChattingWithFile,
    })
  ).data.data;
};

const generateFields = async (
  operation: string,
  options: GenerateOptions & { features?: Features; sourceIds?: string[] }
) => {
  return fetchClient.post(`/api/generate-fields`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...options,
      operation,
    }),
  });
};

const translate = async (options: GenerateOptions) => {
  return fetchClient.post(`/api/generate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...options, operation: "translate" }),
  });
};

const detectAIContent = async (content: string) => {
  return (
    await apiClient.post(`/api/ai-detect`, {
      content,
    })
  ).data.data;
};

const scheduleRewriteToDifferentLanguages = async ({
  html,
  languages,
  collectionId,
  postTitle,
  metaTitle,
  metaDescription,
  slug,
  faqs,
}: {
  html: string;
  languages: string[];
  collectionId?: string;
  postTitle?: string;
}) => {
  return (
    await apiClient.post(`/api/rewrite`, {
      html,
      languages,
      collectionId,
      postTitle,
      type: RewriteType.Language,
      metaTitle,
      metaDescription,
      slug,
      faqs,
    })
  ).data;
};

const generateHeadlines = async ({
  topic,
  keywords,
  language,
}: {
  topic: string;
  keywords: string[];
  language?: string;
}) => {
  return (
    await apiClient.post(`/api/generate`, {
      topic,
      keywords,
      operation: "generate-headlines",
      language,
    })
  ).data.data;
};

const generateCategories = async ({ content }: { content: string }) => {
  return (
    await apiClient.post(`/api/generate`, {
      content,
      operation: "generate-categories",
    })
  ).data.data;
};

const generateFaqs = async ({
  selectedText,
  previousQuestions,
}: {
  selectedText: string;
  previousQuestions?: string[];
}): Promise<{ answer: string; question: string }[]> => {
  return (
    await apiClient.post(`/api/generate-node`, {
      contextText: selectedText,
      previousQuestions,
      operation: "faqs",
    })
  ).data.data;
};

const handleInteractiveText = async (payload: EnhanceContext) => {
  if (payload.operation) {
    trackEditorInteractiveCommand(payload.operation);
  }

  return fetchClient.post(`/api/generate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
};

const generateTalkingPoints = async ({
  topic,
  heading,
  keywords,
}: {
  topic: string;
  heading: string;
  keywords: string[];
}) => {
  return (
    await apiClient.post(`/api/generate`, {
      topic,
      heading,
      keywords,
      operation: "generate-talking-points",
    })
  ).data.data;
};

const generateCitation = async (data: {
  params?: {
    style: string;
    source: string;
    query: string;
  };
  data?: Record<string, any>;
}) => {
  return (
    await apiClient.post(`/api/citation`, {
      ...data,
    })
  ).data.data;
};

const searchPagesByKeywords = async (
  keywords: string | string[]
): Promise<SearchPageItem[]> => {
  return (
    await apiClient.post(`/api/search`, {
      operation: "search-pages",
      query: keywords,
    })
  ).data.data;
};

const searchImagesByKeywords = async (
  keywords: string
): Promise<ImageItem[]> => {
  return (
    await apiClient.post(`/api/search`, {
      operation: "search-images",
      query: keywords,
    })
  ).data.data;
};

const trackImageDownload = async ({
  location,
}: {
  location: string;
}): Promise<ImageItem[]> => {
  return await apiClient.post(`/api/op/track`, {
    location,
  });
};

const searchPagesByUrls = async (urls: string[]): Promise<SearchPageItem[]> => {
  return (
    await apiClient.post(`/api/search`, {
      operation: "search-urls",
      query: urls,
    })
  ).data.data;
};

const searchDocumentsByQuery = async (
  query: string
): Promise<CollectionWithPosts[]> => {
  return (
    await apiClient.post(`/api/search`, {
      operation: "search-documents",
      query: query,
    })
  ).data.data;
};

const getKeywordsForKeywords = async (
  query: string
): Promise<KeywordResult[]> => {
  return (
    await apiClient.post(`/api/search`, {
      operation: "keywords-for-keywords",
      query: query,
    })
  ).data.data;
};

const searchByBookTitle = async (title: string): Promise<Book[]> => {
  return (
    await apiClient.post(`/api/search`, {
      source: "book",
      query: title,
    })
  ).data.data;
};

const searchByWebUrl = async (url: string): Promise<Webpage[]> => {
  return (
    await apiClient.post(`/api/search`, {
      source: "webpage",
      query: url,
    })
  ).data.data;
};
const searchByJournalUrl = async (url: string): Promise<JournalData[]> => {
  return (
    await apiClient.post(`/api/search`, {
      source: "journal",
      query: url,
    })
  ).data.data;
};

const searchByVideo = async (query: string): Promise<Video[]> => {
  return (
    await apiClient.post(`/api/search`, {
      source: "video",
      query: query,
    })
  ).data.data;
};

const createStripeCustomer = async () => {
  return await apiClient.post("api/users/stripe-customer");
};

const generateEmbeddingsFromFileContent = async (params: {
  id: string;
  source: string;
  fileContent: { name: string; content: string }[];
  filePath: string;
}) => {
  return fetchClient.post(`/api/train`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });
};

const summaryStreamByDocuments = async (params: {
  fileContent: { name: string; content: string }[];
}) => {
  return fetchClient.post(`/api/summarize`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  });
};

const getAllDomainLinksByUrls = async ({
  urls,
  includePattern,
  excludePattern,
  crawNewPagesOnly,
}: {
  urls: string[];
  includePattern: string[];
  excludePattern: string[];
  crawNewPagesOnly: boolean;
}): Promise<string[]> => {
  return (
    await apiClient.post(`/api/search`, {
      operation: "search-domain-links",
      urls: urls,
      includePattern,
      excludePattern,
      crawNewPagesOnly,
    })
  ).data.data;
};

const connectWebsites = async (params: {
  webUrls: string[];
  scanWholeWebsite?: boolean;
  source: FromType.webPage;
}) => {
  return apiClient.post(`/api/train`, {
    ...params,
  });
};

const publishCollectionToApi = async ({
  collectionId,
  schedulePublishConfig,
  baseURL,
}) => {
  return apiClient.post(`/api/public/collections`, {
    collectionId,
    schedulePublishConfig,
    baseURL,
  });
};

const unpublishCollectionToApi = async (collectionId: string) => {
  return apiClient.post(`/api/public/collections`, {
    collectionId,
    type: "delete",
  });
};

const getIfCollectionIsPublish = async (collectionId: string) => {
  return !!(
    await apiClient.post(`/api/public/collection`, {
      id: collectionId,
    })
  ).data.data;
};

const getConnectedWebsites = async (): Promise<ConnectedWebsitesResponse> => {
  return (await apiClient.get(`/api/files?type=${FromType.webPage}`)).data.data;
};

const bulkDeleteFiles = async ({
  sourceIds,
}: {
  sourceIds: string[];
}): Promise<void> => {
  return await apiClient.post(`/api/files`, {
    sourceIds,
    operation: "delete",
  });
};

const uploadInfoBaseItem = async (params: {
  id: string;
  name: string;
  source: string;
  webUrls?: string[];
  category: string;
  fileContent?: { name: string; content: string }[];
}) => {
  return await apiClient.post(`/api/train`, {
    ...params,
    partialUpload: true,
  });
};

const updateInfoBaseItem = async (
  id: string,
  params: { name?: string; category?: string }
) => {
  return await apiClient.patch(`/api/files/${id}`, params);
};

const unsubscribeEmailByTokenId = async (
  id: string
): Promise<{
  key: BackendEmailKey;
}> => {
  return (
    await apiClient.post(`/api/unsubscribe`, {
      token: id,
    })
  ).data.data;
};

const resubscribeEmailByTokenId = async (
  id: string
): Promise<{
  key: BackendEmailKey;
}> => {
  return (
    await apiClient.post(`/api/resubscribe`, {
      token: id,
    })
  ).data.data;
};

const improvePrompt = async (
  config: { content: string } | GenerateImageParams,
  type: "chat" | "music" | "image" = "chat"
): Promise<{ content: string }> => {
  return (
    await apiClient.post(`/api/op/improve`, {
      ...config,
      type,
    })
  ).data.data;
};

const generateMusic = async (params: {
  description: string;
  duration: number;
}): Promise<{
  ids: [];
}> => {
  return (await apiClient.post(`/api/music`, params)).data.data;
};

const pullMusic = async (params: {
  ids: string[];
}): Promise<{
  ids: [];
}> => {
  return (await apiClient.post(`/api/music/get`, params)).data.data;
};

const generateStyle = async (params: {
  name: string;
  url?: string;
  text?: string;
}): Promise<{
  name: string;
  style: string;
  description: string;
  id: string;
}> => {
  return (await apiClient.post(`/api/generate/style`, params)).data.data;
};

const deleteFileById = async (id: string): Promise<void> => {
  return await apiClient.delete(`/api/files/${id}`);
};

const updateStyleById = async (
  id: string,
  update: Partial<Style>
): Promise<void> => {
  return await apiClient.patch(`/api/styles/${id}`, update);
};

const deleteStyleById = async (id: string): Promise<void> => {
  return await apiClient.delete(`/api/styles/${id}`);
};

const deletePromptById = async (id: string): Promise<void> => {
  return await apiClient.delete(`/api/prompts/${id}`);
};

const updatePromptById = async (
  id: string,
  update: Partial<Prompt>
): Promise<void> => {
  return (
    await apiClient.patch(`/api/prompts/${id}`, omit(update, ["categories"]))
  ).data.data;
};

const createPrompt = async (prompt: Omit<Prompt, "id">): Promise<Prompt> => {
  return (await apiClient.post(`/api/prompts`, omit(prompt, ["categories"])))
    .data.data;
};

const login = async (data: {
  email: string;
  fullName?: string;
  redirectUrl: string;
  locale: string;
}): Promise<Prompt> => {
  return await apiClient.post(`/api/auth/login`, data);
};

const scheduleWorkflow = async (
  params: WorkflowJobConfigPayload
): Promise<{
  workflow: Workflow;
  job: {
    id: string;
  };
}> => {
  return (await apiClient.post(`/api/workflows`, params)).data.data;
};

const scheduleWorkflowOutlineById = async (
  id: string,
  params: WorkflowJobConfigPayload
): Promise<{
  workflow: Workflow;
  job: {
    id: string;
  };
}> => {
  return (await apiClient.post(`/api/workflows/${id}`, params)).data.data;
};

const scheduleWorkflowContentById = async (
  id: string,
  params: WorkflowJobConfigPayload
): Promise<{
  postId: string;
  collectionId: string;
  job: {
    id: string;
  };
}> => {
  return (await apiClient.post(`/api/workflows/${id}`, params)).data.data;
};

const scheduleBulkGenerate = async (
  params: BulkGenerateJobConfigPayload
): Promise<{
  collectionId: string;
  jobId: string;
}> => {
  return (await apiClient.post(`/api/bulk-generate`, params)).data.data;
};

const scheduleImproveArticle = async (
  params: ArticleImproverPayload
): Promise<{
  collectionId: string;
  postId: string;
}> => {
  return (await apiClient.post(`/api/improve-article`, params)).data.data;
};

const getUserNotifications = async (): Promise<Notification[]> => {
  return (await apiClient.get(`/api/notifications`)).data.data;
};

const getStatus = async () => {
  return (await apiClient.get(`/api/status`)).data.data;
};

const getWebflowSites = async (apiToken: string) => {
  return (
    await apiClient.post(`/api/integrations/webflow/collections`, {
      apiToken: apiToken,
    })
  ).data.data;
};

const saveWebflowIntegration = async (params) => {
  return (await apiClient.post(`/api/integrations/webflow`, params)).data.data;
};

const getIntegrations = async () => {
  return (await apiClient.get(`/api/integrations`)).data.data;
};

const getWordpressCommonData = async (id: string) => {
  return (await apiClient.get(`/api/integrations/${id}/wordpress-common`)).data
    .data;
};
const getGhostCommonData = async (id: string) => {
  return (await apiClient.get(`/api/integrations/${id}/ghost-common`)).data
    .data;
};
const getShopifyCommonData = async (id: string) => {
  return (await apiClient.get(`/api/integrations/${id}/shopify-common`)).data
    .data;
};

const getWebflowCollection = async ({
  collectionId,
  apiToken,
}: {
  apiToken: string;
  collectionId: string;
}) => {
  return (
    await apiClient.post(`/api/integrations/webflow/collections`, {
      apiToken: apiToken,
      collectionId,
    })
  ).data.data;
};

const saveIntegration = async (params) => {
  return (await apiClient.post(`/api/integrations`, params)).data.data;
};
const updateIntegration = async (id, params) => {
  return (await apiClient.patch(`/api/integrations/${id}`, params)).data.data;
};
const deleteIntegration = async (id) => {
  return await apiClient.delete(`/api/integrations/${id}`);
};

const getFolders = async (): Promise<Folder[]> => {
  return (await apiClient.get(`/api/folders`)).data.data;
};

const getFolderById = async (id: string): Promise<Folder> => {
  return (await apiClient.get(`/api/folders/${id}`)).data.data;
};

const onCreateFolder = async (name: string): Promise<Folder> => {
  return (
    await apiClient.post(`/api/folders`, {
      name,
    })
  ).data.data;
};

const onDeleteFolder = async (id: string): Promise<Folder> => {
  return (await apiClient.delete(`/api/folders/${id}`)).data;
};

const onUpdateFolder = async (id: string, name: string): Promise<Folder> => {
  return (
    await apiClient.patch(`/api/folders/${id}`, {
      title: name,
    })
  ).data.data;
};

const onMoveCollection = async (collectionIds: string[], folderId?: string) => {
  return (
    await apiClient.post(`/api/collections/move`, {
      collectionIds,
      folderId,
    })
  ).data;
};

const validateShopifyCredentials = async (credentials) => {
  return (await apiClient.post(`/api/shopify/validate`, credentials)).data;
};

const suggestLinks = async ({ linkIds, content, limit }) => {
  return (
    await apiClient.post(`/api/suggest-links`, {
      linkIds,
      content,
      limit,
    })
  ).data.data;
};

const getApiKey = async () => {
  return (await apiClient.get(`/api/api-key`)).data.data;
};

const generateApiKey = async () => {
  return (await apiClient.post(`/api/api-key`)).data.data;
};

const deleteApiKey = async () => {
  return await apiClient.delete(`/api/api-key`);
};

export default {
  generateApiKey,
  getApiKey,
  deleteApiKey,
  suggestLinks,
  getStatus,
  condensedText,
  updateCollectionById,
  saveCollectionById,
  getCollections,
  getCollectionById,
  getAuthors,
  getCommonData,
  createAuthor,
  updateAuthorById,
  deleteAuthorById,
  saveSettings,
  getPresignedUrl,
  uploadImageByFile,
  stripeCheckout,
  redirectToCustomerProtal,
  updateUserById,
  updateContentfulAccessToken,
  deleteCollectionById,
  deletePostById,
  onRestoreImage,
  onSendMessageToChat,
  onGenerateArtImage,
  onGenerateArtImageVariations,
  getUserChats,
  getFullChatByChatId,
  getGhostCommonData,
  deleteChatByChatId,
  createChat,
  addMessageToChat,
  generateFields,
  translate,
  createStripeCustomer,
  detectAIContent,
  generateCitation,
  searchByBookTitle,
  searchByWebUrl,
  searchByVideo,
  connectWebsites,
  generateEmbeddingsFromFileContent,
  unsubscribeEmailByTokenId,
  resubscribeEmailByTokenId,
  searchByJournalUrl,
  improvePrompt,
  generateStyle,
  deleteStyleById,
  updateStyleById,
  deleteFileById,
  uploadInfoBaseItem,
  updateInfoBaseItem,
  deletePromptById,
  updatePromptById,
  createPrompt,
  login,
  searchPagesByKeywords,
  scheduleWorkflow,
  searchPagesByUrls,
  generateHeadlines,
  generateTalkingPoints,
  handleInteractiveText,
  generateImageBaseOnContext,
  generateFaqs,
  searchImagesByKeywords,
  trackImageDownload,
  getConnectedWebsites,
  bulkDeleteFiles,
  getAllDomainLinksByUrls,
  publishToWordPress,
  onDocSearch,
  summaryStreamByDocuments,
  importFromWordPress,
  getUserWorkflows,
  deleteUserWorkflow,
  getUserWorkflowById,
  scheduleWorkflowOutlineById,
  scheduleWorkflowContentById,
  getUserNotifications,
  generateMusic,
  pullMusic,
  scheduleBulkGenerate,
  generateCategories,
  searchDocumentsByQuery,
  getKeywordsForKeywords,
  publishToMedium,
  publishToReddit,
  scheduleRewriteToDifferentLanguages,
  publishCollectionToApi,
  getIfCollectionIsPublish,
  unpublishCollectionToApi,
  getWebflowSites,
  getWebflowCollection,
  saveWebflowIntegration,
  getIntegrations,
  publishToWebflow,
  saveIntegration,
  updateIntegration,
  deleteIntegration,
  onCreateFolder,
  getFolders,
  onDeleteFolder,
  onUpdateFolder,
  onMoveCollection,
  getFolderById,
  deleteCollectionByIds,
  getWordpressCommonData,
  validateShopifyCredentials,
  updateUserPassword,
  getShopifyCommonData,
  getUserCurrentTeam,
  removeUserFromTeam,
  inviteUserToTeam,
  cancelPostSchedule,
  scheduleImproveArticle,
  publishToGhost,
  suggestArticlesToLinkTo,
  generateLinksForArticles,
};
