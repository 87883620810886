import { primaryScrollbar } from "../sytle-constants";

const globals = {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    transition: "color 150ms ease-in !important",
  },
  body: primaryScrollbar,
};

export default globals;
