import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime"; // ES 2015

dayjs.extend(relativeTime);
dayjs.extend(utc);

const date = () => dayjs.utc();

const withUti = dayjs.utc;

export { withUti as dayjs };
export default date;
