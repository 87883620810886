import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Container, ResponsiveText } from "../front-page/shared";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { graidentTextStyle } from "modules/non-protected-page/heros/shared";
import { InlineSpan } from "components/as-inline-span";
import CountDownTimer from "components/coutdown/CountDownTimer";

import React from "react";
import { dayjs } from "client-server-shared/utils/dayjs";
import { selectUserInYearlyPlan } from "selectors/user";
import { useSelector } from "hooks/use-selector";

const bfDate = "2024-11-11T17:31:47.983Z";
const oneWeekAfter = dayjs(bfDate).add(6, "day").toISOString();

export const isBlackFridayBannerActive = () => {
  return !dayjs().isAfter(oneWeekAfter);
};

export const BlackFridayBanner = React.memo(() => {
  const [open, setOpen] = React.useState(true);
  const active = isBlackFridayBannerActive();
  if (!active) {
    return null;
  }
  if (!open) {
    return null;
  }
  return (
    <Box
      sx={{
        background: "#111827",
        padding: "12px",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          paddingTop: "12px",
          borderRadius: "6px",
          paddingBottom: "12px",
          justifyContent: "space-between",
          columnGap: "48px",
          rowGap: "6px",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          background: "#E6E6FA",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            columnGap: "24px",
            padding: {
              xs: "0",
              sm: "0",
              md: "0",
              lg: "0 24px",
            },
            rowGap: "12px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "6px",
            }}
          >
            <ResponsiveText
              fontSize={16}
              sx={{
                color: "text.black",
                marginRight: "auto",
              }}
            >
              <InlineSpan
                inherit
                sx={{
                  fontWeight: "700!important",
                }}
              >
                Special Black Friday Deal
              </InlineSpan>
              <InlineSpan inherit> – Up to </InlineSpan>

              <InlineSpan
                inherit
                sx={{
                  ...graidentTextStyle,
                  fontWeight: "700!important",

                  background:
                    "linear-gradient(to right, #D400BF, rgba(76, 56, 198, 0.85) 143.99%, rgba(8, 8, 8, 0) 100%)",
                }}
              >
                50% off{" "}
              </InlineSpan>
              <InlineSpan
                inherit
                sx={{
                  fontWeight: 400,
                }}
              >
                on Annual Plans (5 months free).
              </InlineSpan>
            </ResponsiveText>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "48px",
              paddingRight: "24px",
              justifyContent: "space-between",
              rowGap: "12px",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <CountDownTimer inFrontPage targetDate={oneWeekAfter} />
          </Box>
          {/*
              <PurplePinkButton
              component={Link}
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                margin: 0,
                padding: "7px 24px",
                borderRadius: "22px",
                background: "#C326FA",
                marginRight: "24px",
                fontSize: "14px",
                fontWeight: 600,
              }}
              href={serverRoutes.pricing}
            >
              {t("Go Unlimited now")}
            </PurplePinkButton>
              */}
        </Box>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
          aria-label={"close"}
          sx={{
            position: "absolute",
            padding: 0,
            border: ".5px solid #FFFFFF",
            opacity: 0.5,
            top: {
              xs: "initial",
              sm: "initial",
              md: "10px",
              lg: "10px",
              xl: "10px",
            },
            bottom: {
              xs: "10px",
              sm: "10px",
              md: "initial",
              lg: "initial",
              xl: "initial",
            },
            right: "10px",
            "&:hover": {
              border: ".5px solid transparent",
            },
          }}
        >
          <CloseOutlinedIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
          />
        </IconButton>
      </Container>
    </Box>
  );
});
