import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "reducers/types";
import {
  Features,
  isCreatorPlanByPriceId,
  isProPlanByPriceId,
  isUnlimitedPlanByPriceId,
  userHasGPT4Enabled,
  isYearlyPlanByPriceId,
  isUserInAppSumoPlan,
} from "client-server-shared/utils/check-subscription";
import { dayjs } from "client-server-shared/utils/dayjs";
import { adminUserIds } from "src/constants";
import {
  PlanName,
  appSumoTier1,
  appSumoTier2,
  appSumoTier3,
  appSumoTier4,
} from "client-server-shared/pricing/v2";
import { IntegrationType } from "client-server-shared/constants";
import { IntegrationServer } from "client-server-shared/types/types";
import { defaultLocation } from "client-server-shared/google-ads-api-country";
import { v4 } from "uuid";

export const selectUser = (state: RootState) =>
  selectSession(state)?.user || null;

export const selectSession = (state: RootState) => state.user.session;

export const selectUserId = (state: RootState) => state.user.session?.user.id;
export const selectUserCreatedAt = (state: RootState) =>
  state.user.userDetails?.created_at;
export const selectIsUserAdmin = createSelector(selectUserId, (userId) => {
  if (!userId) {
    return false;
  }
  return adminUserIds.includes(userId);
});

export const selectActivePlan = (state: RootState) => state.user.activePlan;
export const selectUserDetails = (state: RootState) => state.user.userDetails;

export const selectUserTeamRole = createSelector(
  selectUserDetails,
  (user) => user?.role || "Owner"
);

export const selectUserTeamId = createSelector(
  selectUserDetails,
  (user) => user?.teamId || ""
);

export const selectUserIsTeamOwner = createSelector(
  selectUserTeamRole,
  (role) => role === "Owner"
);

export const selectUserLanguage = createSelector(
  selectUserDetails,
  (details) => {
    return details?.locale === "en" ? "English" : details?.locale || "English";
  }
);

export const selectUserLocation = createSelector(
  selectUserDetails,
  (details) => {
    return details?.location || defaultLocation;
  }
);

export const selectUserShopify = createSelector(selectUserDetails, (user) => {
  if (
    !user?.shopify_access_token ||
    !user?.shopify_secret_key ||
    !user?.shopify_url
  ) {
    return null;
  }
  return {
    accessToken: user.shopify_access_token,
    secretKey: user.shopify_secret_key,
    url: user.shopify_url,
  };
});

export const selectUserInLegacyPlan = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.inLegacyPlan || false;
  }
);

export const selectShowOnboarding = createSelector(
  selectUserDetails,
  (user) => {
    if (!user) {
      return false;
    }
    if (user.onboardingOption) {
      return false;
    }

    return true;
  }
);

export const selectUserMediumToken = createSelector(
  selectUserDetails,
  (userDetails) => userDetails?.medium_access_token || ""
);

export const selectUserReddit = createSelector(selectUserDetails, (user) => {
  return {
    reddit_id: user?.reddit_id || "",
    reddit_secret: user?.reddit_secret || "",
    reddit_username: user?.reddit_username || "",
    reddit_password: user?.reddit_password || "",
    subreddits: user?.subreddits || [],
  };
});

export const selectUserWordsUsed = createSelector(
  selectUserDetails,
  (userDetails) => userDetails?.totalWordsUsed || 0
);

export const selectUserFreeWorkflows = createSelector(
  selectUserDetails,
  (userDetails) => userDetails?.workflows || 0
);
export const selectUserWorkflows = createSelector(
  selectActivePlan,
  (activePlan) => activePlan?.workflows
);

export const selectUserRemainingWords = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.totalCredits || 0;
  }
);

export const selectUserTotalWords = createSelector(
  selectUserRemainingWords,
  selectUserWordsUsed,
  (remainingWords: number, wordsUsed: number) => {
    return remainingWords + wordsUsed;
  }
);

export const selectUserFreeWorkflowsUsed = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.workflowsUsed || 0;
  }
);

export const selectUserPaidWords = createSelector(
  selectUserDetails,
  (userDetails) => userDetails?.paidCredits || 0
);

export const selectUserPaidWorkflows = createSelector(
  selectUserDetails,
  (userDetails) => userDetails?.paidWorkflows || 0
);

export const selectUserUsedPaidWorkflows = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.paidWorkflowsUsed || 0;
  }
);

export const selectUserTotalPaidWorkflows = createSelector(
  selectUserUsedPaidWorkflows,
  selectUserPaidWorkflows,
  (usedPaidWorkflows, paidWorkflows) => {
    return usedPaidWorkflows + paidWorkflows;
  }
);

export const selectUserAdvancedWords = createSelector(
  selectActivePlan,
  selectUserPaidWords,
  (activePlan, paidWords) => {
    return (activePlan?.advancedWords || 0) + (paidWords || 0);
  }
);

export const selectUserFreeAIImages = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.freeAiImages || 0;
  }
);
export const selectUserRemainingAImages = createSelector(
  selectUserFreeAIImages,
  selectActivePlan,
  (freeImages, activePlan) => {
    return freeImages + (activePlan?.aiImages || 0);
  }
);

export const selectUserUsedFreeAIImages = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.freeAiImagesUsed || 0;
  }
);

const emptyFeatures: Features = {};

export const selectFeatures = createSelector(
  selectUserDetails,
  (userDetails) => {
    if (!userDetails) {
      return emptyFeatures;
    }
    return userDetails.features as Features;
  }
);
export const selectUserRemainingWorkflows = createSelector(
  selectUserPaidWorkflows,
  selectUserFreeWorkflows,
  selectUserWorkflows,
  (paidWorkflows, freeWorkflows, workflows) => {
    return (paidWorkflows || 0) + freeWorkflows + (workflows || 0);
  }
);

export const selectUserEmail = createSelector(selectSession, (session) => {
  return session?.user.email;
});

export const selectUserHasActiveSubscription = createSelector(
  selectActivePlan,
  selectUser,
  (activePlan, user) => !!(user && activePlan)
);

export const selectUserInAppSumoPlanTierOne = createSelector(
  selectActivePlan,
  (plan) => {
    return plan?.priceId && appSumoTier1.priceIds.includes(plan.priceId);
  }
);

export const selectUserInAppSumoPlanTierTwo = createSelector(
  selectActivePlan,
  (plan) => {
    return plan?.priceId && appSumoTier2.priceIds.includes(plan.priceId);
  }
);

export const selectUserInAppSumoPlanTierThree = createSelector(
  selectActivePlan,
  (plan) => {
    return plan?.priceId && appSumoTier3.priceIds.includes(plan.priceId);
  }
);
export const selectUserInAppSumoPlanTierFour = createSelector(
  selectActivePlan,
  (plan) => {
    return plan?.priceId && appSumoTier4.priceIds.includes(plan.priceId);
  }
);

export const selectIsGPT4Enabled = createSelector(
  selectFeatures,
  selectUserHasActiveSubscription,
  (features, hasActiveSubscription) => {
    return hasActiveSubscription && userHasGPT4Enabled(features);
  }
);

export const selectUserWorkflowsGivenInPeriod = createSelector(
  selectActivePlan,
  (activePlan) => {
    return activePlan?.workflowsGivenInCurrentPeriod || 0;
  }
);

export const selectUserHasEnoughWorkflows = createSelector(
  selectUserRemainingWorkflows,
  (workflows) => {
    return workflows > 0;
  }
);

export const selectUserWorkflowsUsedInPeriod = createSelector(
  selectActivePlan,
  (activePlan) => {
    return activePlan?.workflowsUsedInCurrentPeriod || 0;
  }
);

export const selectUserAIImagesGivenInPeriod = createSelector(
  selectActivePlan,
  (activePlan) => {
    return activePlan?.aiImagesGivenInCurrentPeriod || 0;
  }
);

export const selectUserAIImagesUsedInPeriod = createSelector(
  selectActivePlan,
  (activePlan) => {
    return activePlan?.aiImagesUsedInCurrentPeriod;
  }
);

export const selectUserInActiveCreatorPlan = createSelector(
  selectActivePlan,
  selectUser,
  (activePlan, user) =>
    user && activePlan && isCreatorPlanByPriceId(activePlan.priceId!)
);

export const selectUserInActiveProPlan = createSelector(
  selectActivePlan,
  selectUser,
  (activePlan, user) =>
    user && activePlan && isProPlanByPriceId(activePlan.priceId!)
);

export const selectUserInAppSumoPlan = createSelector(
  selectActivePlan,
  selectUser,
  (activePlan, user) =>
    user && activePlan && isUserInAppSumoPlan(activePlan.priceId!)
);

export const selectUserAppSumoInvoiceId = createSelector(
  selectActivePlan,
  (plan) => {
    if (plan) {
      return plan.metadata?.appSumoInvoiceItemUuid;
    }
    return "";
  }
);

export const selectUserInActiveUnlimitedPlan = createSelector(
  selectActivePlan,
  selectUser,
  (activePlan, user) =>
    user && activePlan && isUnlimitedPlanByPriceId(activePlan.priceId!)
);

export const selectUserRemainingAdvancedWords = createSelector(
  selectUserInActiveUnlimitedPlan,
  selectUserRemainingWords,
  selectUserAdvancedWords,
  (inUnlimitedPlan, renamingWords, advancedWords) => {
    if (inUnlimitedPlan) {
      return advancedWords;
    }
    return Math.round(renamingWords / 2);
  }
);

export const getUserCurrentPlanName = createSelector(
  selectUserInActiveUnlimitedPlan,
  selectUserInActiveCreatorPlan,
  selectUserInActiveProPlan,
  selectActivePlan,
  (isUnlimited, isCreatorPlan, isProPlan, activePlan) => {
    if (isUnlimited) {
      return PlanName.unlimited;
    }
    if (isCreatorPlan) {
      return PlanName.creator;
    }
    if (isProPlan) {
      return PlanName.pro;
    }
    if (activePlan?.planName) {
      return activePlan.planName;
    }
    return "Free";
  }
);

export const selectUserInYearlyPlan = createSelector(
  selectActivePlan,
  selectUser,
  (activePlan, user) => {
    return user && activePlan && isYearlyPlanByPriceId(activePlan.priceId!);
  }
);

export const selectUserCanHavePaidTrial = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.canHavePaidTrial || false;
  }
);

export const selectUserInPaidTrial = createSelector(
  selectActivePlan,
  (activePlan) => {
    return activePlan?.status === "trialing";
  }
);

export const selectUserPaidTrialEndDate = createSelector(
  selectActivePlan,
  selectUserInPaidTrial,
  (activePlan, inPaidTrail) => {
    if (!inPaidTrail) {
      return null;
    }
    return activePlan?.trialEnd;
  }
);

export const selectNextBillingPeriod = createSelector(
  selectActivePlan,
  selectUserInPaidTrial,
  (activePlan, inPaidTrail) => {
    if (inPaidTrail) {
      return null;
    }
    if (activePlan?.cancelAtPeriodEnd) {
      return null;
    }
    return activePlan?.nextGivenAt;
  }
);

export const getUserCurrentPlanQuality = createSelector(
  getUserCurrentPlanName,
  (planName) => {
    if (planName === PlanName.unlimited) {
      return "Ultimate";
    }
    if (planName === PlanName.pro || planName === PlanName.creator) {
      return "Premium";
    }
    return "High";
  }
);

export const selectIsFirstTimeChatWithFile = createSelector(
  selectUserDetails,
  (userDetails) => {
    return userDetails?.isFirstTimeChatWithFile;
  }
);

export const selectUserHasContentfulIntegrated = createSelector(
  selectUserDetails,
  (userDetails) => !!userDetails?.encrypted_contentful_access_token
);

const selectBaseNotification = (state: RootState) => state.user.notifications;

const emptyNotfication = [];

export const selectUserNotifications = createSelector(
  selectBaseNotification,
  (notifications) => {
    if (!notifications) return emptyNotfication;

    return notifications.filter((notification) => {
      const seen = notification.seen;
      const expired = notification.expires_at
        ? dayjs(notification.expires_at).isBefore(dayjs())
        : false;
      return !seen && !expired;
    });
  }
);

const emptyIntegration: IntegrationServer[] = [];

export const defaultApiIntegration = {
  id: v4(),
  type: IntegrationType.API,
  name: "Junia AI API",
};

const selectIntegrations = (state: RootState) => state.user.integrations;

export const selectBaseIntegrations = createSelector(
  selectIntegrations,
  (integrations) => {
    return [defaultApiIntegration, ...(integrations || emptyIntegration)];
  }
);

export const selectUserHasWordPressIntegrated = createSelector(
  selectBaseIntegrations,
  (integrations) =>
    (integrations || emptyIntegration).filter(
      (integration) => integration.type === IntegrationType.WordPress
    ).length > 0
);

export const selectIntegrationsByType = createSelector(
  [selectBaseIntegrations, (state, type: IntegrationType) => type],
  (integrations, type) => {
    const found = (integrations || emptyIntegration).filter(
      (integration) => integration.type === type
    );
    return found;
  }
);
