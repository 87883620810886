import { cleanStr, strippedHtml } from "./text-utils";
import { countWords as originalCountWords } from "alfaaz";

export const countWordsPlain = (str: string) => {
  return originalCountWords(str);
};

export const countWords = (str: string) => {
  return originalCountWords(cleanStr(str).trim());
};

export const countWordsV2 = (str: string) => {
  return originalCountWords(strippedHtml(str));
};
