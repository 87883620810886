import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Modal = {
  id: string;
  component: React.ReactNode;
};

type ModalsState = Modal[];

const initialState: ModalsState = [];

export const modalsSlice = createSlice({
  name: "modals",
  initialState: initialState,
  reducers: {
    openModal: (state, action: PayloadAction<Modal>) => {
      state.push(action.payload);
      return state;
    },
    removeModal: (state, action: PayloadAction<string>) => {
      return state.filter((modal) => modal.id !== action.payload);
    },
  },
});

export const { openModal, removeModal } = modalsSlice.actions;

export default modalsSlice.reducer;
