import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { hydrate } from "../actions";
import { Team } from "client-server-shared/types/team";
import { maxTeamSize } from "client-server-shared/constants";

interface TeamState {
  id?: string | null;
  teamUsers?: Team["teamUsers"] | null;
  teamSize: number;
}

const initialTeamState: TeamState = {
  id: null,
  teamUsers: null,
  teamSize: maxTeamSize,
};

export const teamSlice = createSlice({
  name: "team",
  initialState: initialTeamState,
  reducers: {
    setTeam: (state, action: PayloadAction<Team | null>) => {
      state.teamUsers = action.payload?.teamUsers;
      state.id = action.payload?.id;
      state.teamSize = action.payload?.maxTeamSize || maxTeamSize;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (state.teamUsers) {
        return state;
      }
      return action.payload.team;
    });
  },
});

export const { setTeam } = teamSlice.actions;

export default teamSlice.reducer;
