import React from "react";
import { initSentry } from "./lazy-sentry";

const SentryLazyLoader = () => {
  React.useEffect(() => {
    if (process.env.NODE_ENV === "development") return;
    if (typeof window !== "undefined") {
      if ("requestIdleCallback" in window) {
        requestIdleCallback(() => {
          requestIdleCallback(() => {
            initSentry({
              dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
            });
          });
        });
      } else {
        setTimeout(() => {
          initSentry({
            dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
          });
        }, 0);
      }
    }
  }, []);

  return null;
};

export default SentryLazyLoader;
