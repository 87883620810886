import MuiContainer, { ContainerTypeMap } from "@mui/material/Container";
import { getStyleProps, passProps } from "modules/themes/styleUtils";

import React from "react";
import type { OverridableComponent } from "@mui/material/OverridableComponent";

export const Container: OverridableComponent<ContainerTypeMap> =
  React.forwardRef((props, ref) => {
    const { children, ...rest } = props;
    return (
      <MuiContainer
        {...passProps(rest)}
        {...getStyleProps(rest)}
        maxWidth={typeof rest.maxWidth === "undefined" ? false : rest.maxWidth}
        ref={ref}
      >
        {children}
      </MuiContainer>
    );
  });

Container.displayName = "Container";
