import MuiPaper, { PaperProps } from "@mui/material/Paper";
import { getStyleProps, passProps } from "modules/themes/styleUtils";

import React from "react";

export { PaperProps };

export const Paper = React.forwardRef((props: PaperProps, ref) => {
  const { children, ...rest } = props;
  return (
    <MuiPaper ref={ref} {...passProps(rest)} {...getStyleProps(rest)}>
      {children}
    </MuiPaper>
  );
});

Paper.displayName = "Paper";
