import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { hydrate } from "../actions";
import { Workflow } from "client-server-shared/types/workflow";

export const workflowAdapter = createEntityAdapter<Workflow>({
  selectId: (workflow) => workflow.id,
  sortComparer: (a, b) =>
    new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
});

export const workflowsSlice = createSlice({
  name: "workflows",
  initialState: workflowAdapter.getInitialState({
    hasMore: true,
    initiated: false,
  }),
  reducers: {
    workflowsAdded: workflowAdapter.upsertMany,
    workflowsDeleted: workflowAdapter.removeMany,
    workflowUpdated: workflowAdapter.updateOne,
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
    setInitiated: (state) => {
      state.initiated = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (state.ids.length > 0) {
        return state;
      }
      return action.payload.workflows;
    });
  },
});

export const {
  workflowsAdded,
  setInitiated,
  setHasMore,
  workflowsDeleted,
  workflowUpdated,
} = workflowsSlice.actions;

export default workflowsSlice.reducer;
