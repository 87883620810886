let supabasePromise: Promise<typeof import("utils/supabase-client").default>;

export const getBrowserSupabase = async () => {
  if (!supabasePromise) {
    supabasePromise = import("utils/supabase-client").then(
      (mod) => mod.default
    );
  }
  return supabasePromise;
};
