import { isAxiosError } from "axios";
import { isPlainObject } from "client-server-shared/utils/lodash-methods";
import { ErrorCode } from "server/errors/utils";

export const defaultError = {
  status: 500,
  message: "",
  code: "",
  data: undefined,
};

export type ErrorJson = {
  status: number;
  message: string;
  code?: string;
  data?: Record<string, any>;
};

export const isTooManyRequestError = (error: Record<string, any>) => {
  return error?.code === ErrorCode.RateLimitError;
};

export const getErrorBodyFromJson = (errorBody: unknown): ErrorJson => {
  if (isPlainObject(errorBody)) {
    if (!("error" in errorBody && "message" in errorBody)) {
      return defaultError;
    }
    return {
      status: errorBody?.status || 500,
      message: errorBody?.message,
      code: errorBody?.code,
      data: isPlainObject(errorBody?.data) ? errorBody?.data : undefined,
    };
  }
  return defaultError;
};

export const getReadableStreamErrorWithFetch = async (response: Response) => {
  try {
    const erorrJson = await response.json();
    return getErrorBodyFromJson(erorrJson);
  } catch (e) {
    return defaultError;
  }
};

export const isNotFoundError = (error: unknown) => {
  const errorJson = getRequestErrorObject(error);
  return errorJson.status === 404;
};

export const getRequestErrorObject = (error: unknown) => {
  if (isAxiosError(error)) {
    const errorJson = getErrorBodyFromJson(error.response?.data);
    return errorJson;
  }
  return getErrorBodyFromJson(error);
};

export const getReadableRateLimitGenerationEndTimeMessage = (
  remainingTime: number
) => {
  const diff = Math.abs(
    new Date(remainingTime).getTime() - new Date().getTime()
  );
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor(diff / 1000 / 60) - hours * 60;

  return `You have reached your daily quota, they will renew in ${hours} hours and ${minutes} minutes. Or upgrade to a higher plan`;
};
