import { useAppDispatch } from "hooks/use-app-dispatch";
import { useSelector } from "hooks/use-selector";
import React from "react";
import { setSession, setUserDetails } from "reducers/user";
import {
  selectIsGPT4Enabled,
  selectSession,
  selectUserDetails,
  selectActivePlan,
} from "selectors/user";
import { useProcessingLog } from "./use-loading";
import { useMounted } from "./use-mounted";

import { dashboardRouteConfig } from "client-server-shared/config/routes";

import { useRouter } from "next/router";
import { getBrowserSupabase } from "utils/supabase-as-lazy";
import protectedClientApi from "utils/protected-client-api";
import { setGlobalSession, setGlobalActivePlan } from "src/constants";

export const useUserFromShopify = () => {
  const session = useSelector(selectSession);

  return !!session?.user?.user_metadata?.createdFromShopify;
};

export const useSession = () => {
  const session = useSelector(selectSession);
  React.useEffect(() => {
    setGlobalSession(session);
  }, [session]);
  return session;
};

export const sessionLoadingLogId = "session-loading-status";

export const userDetailsLoadingLogId = "user-details-loading-status";

export const useLoadSession = () => {
  const { initiated, addLog, onSuccess, onError } =
    useProcessingLog(sessionLoadingLogId);
  const dispatch = useAppDispatch();
  const { safeRun } = useMounted();

  const loadSessionOnce = React.useCallback(async () => {
    if (initiated) {
      return;
    }
    try {
      addLog();
      const supabaseClient = await getBrowserSupabase();
      const {
        data: { session },
        error,
      } = await supabaseClient.auth.getSession();
      safeRun(() => {
        if (error) {
          onError();
        } else {
          onSuccess();
        }
      });

      dispatch(setSession(session));
    } catch (e) {
      safeRun(() => {
        onError();
      });
    }
  }, [initiated]);

  return {
    loadSessionOnce,
  };
};

export const useUser = () => {
  const user = useSession()?.user || null;

  return user;
};

export const useRedirectInLogin = () => {
  const user = useUser();
  const router = useRouter();
  const { safeRun } = useMounted();
  const hasTryNavigating = React.useRef(false);
  const { loadSessionOnce } = useLoadSession();

  React.useEffect(() => {
    loadSessionOnce();
  }, []);

  React.useEffect(() => {
    if (user) {
      if (hasTryNavigating.current) {
        return;
      }
      if (typeof window !== "undefined") {
        const searchParams = new URLSearchParams(window.location.search);
        const from = searchParams.get("from");
        safeRun(() => {
          hasTryNavigating.current = true;
          router.replace(from || dashboardRouteConfig.dashboard);
        });
      }
    }
  }, [user]);
};

export const useToggleGpt4Feature = () => {
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useAppDispatch();
  const gpt4Enabled = useSelector(selectIsGPT4Enabled);
  const onToggle = React.useCallback(async () => {
    if (!userDetails) {
      return;
    }
    const nextDetails = {
      features: { gpt4: !gpt4Enabled },
    };
    dispatch(
      setUserDetails({
        ...userDetails,
        ...nextDetails,
      })
    );
    await protectedClientApi.updateUserById(userDetails.id, nextDetails);
  }, [gpt4Enabled, dispatch, userDetails]);
  return { gpt4Enabled, onToggle };
};

export const useSetUserActivePlan = () => {
  const activePlan = useSelector(selectActivePlan);
  React.useEffect(() => {
    setGlobalActivePlan(activePlan);
  }, [activePlan]);
};
