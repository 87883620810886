import type { SxProps } from "@mui/system";
import type { Theme } from "@mui/material/styles";

import { CSSProperties } from "react";
import { ExtendedStyleProps } from "./custom-types";
import boxShadows from "./base/boxShadows";
import colors from "./base/colors";

export const flexRowCenter = {
  display: "flex",
  alignItems: "center",
};

export const flexRowSpaceBetween = {
  ...flexRowCenter,
  justifyContent: "space-between",
};

export const alignColumnCenter = {
  ...flexRowCenter,
  flexDirection: "column",
};
export const spaceBetweenCenter = {
  ...flexRowCenter,
  justifyContent: "space-between",
};

export const absoluteRowCenter = {
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, 0)",
};

export const absoluteColumnCenter = {
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
};

export const absoluteBottomCenter = {
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translate(-50%, 0)",
};

export const absoluteColumnCenterLeft = {
  ...absoluteColumnCenter,
  left: 0,
};
export const absoluteColumnCenterRight = {
  ...absoluteColumnCenter,
  right: 0,
};

export const absoluteCenter = {
  ...absoluteRowCenter,
  ...absoluteColumnCenter,
  transform: "translate(-50%,-50%)",
};

export const passProps = <T extends Record<string, any>>(props: T): T => {
  const {
    sameLine,
    spaceBetween,
    justifyContentStart,
    alignCenter,
    displayColumn,
    fullWidth,
    alignStart,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    margin,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    position,
    capitalize,
    noShadow,
    borderColor,
    displayFlex,
    justifyContentCenter,
    fontSize,
    uppercase,
    lowercase,
    minHeight,
    boxShadow,
    textColor,
    backgroundColor,
    alignItems,
    withUnderline,
    ...rest
  } = props;
  return rest;
};

export const getStyleProps = ({
  sameLine,
  spaceBetween,
  justifyContentStart,
  alignCenter,
  displayColumn,
  fullWidth,
  alignStart,
  padding,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  margin,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  position,
  textColor,
  capitalize,
  noShadow,
  backgroundColor,
  borderColor,
  displayFlex,
  justifyContentCenter,
  fontSize,
  uppercase,
  lowercase,
  minHeight,
  boxShadow,
  withUnderline,
  sx = {},
}: ExtendedStyleProps & { sx?: SxProps<Theme> } & Record<string, unknown>) => {
  const style: CSSProperties = {};

  if (withUnderline) {
    style["textDecoration"] = "underline";
  }

  if (fontSize) {
    style["fontSize"] = `${fontSize}px`;
  }
  if (uppercase) {
    style["textTransform"] = "uppercase";
  }
  if (lowercase) {
    style["textTransform"] = "lowercase";
  }

  if (typeof minHeight === "string") {
    style["minHeight"] = minHeight;
  } else if (typeof minHeight === "number") {
    style["minHeight"] = `${minHeight}px`;
  }

  if (typeof margin === "number") {
    style["margin"] = `${margin}px`;
  }

  if (typeof margin === "string") {
    style["margin"] = margin;
  }

  if (typeof marginLeft === "number") {
    style["marginLeft"] = `${marginLeft}px`;
  }
  if (typeof marginLeft === "string") {
    style["marginLeft"] = marginLeft;
  }

  if (typeof marginRight === "number") {
    style["marginRight"] = `${marginRight}px`;
  }
  if (typeof marginRight === "string") {
    style["marginRight"] = marginRight;
  }

  if (typeof marginTop === "number") {
    style["marginTop"] = `${marginTop}px`;
  }
  if (typeof marginTop === "string") {
    style["marginTop"] = marginTop;
  }

  if (typeof marginBottom === "number") {
    style["marginBottom"] = `${marginBottom}px`;
  }
  if (typeof marginBottom === "string") {
    style["marginBottom"] = marginBottom;
  }

  if (typeof paddingTop === "number") {
    style["paddingTop"] = `${paddingTop}px`;
  }
  if (typeof paddingTop === "string") {
    style["paddingTop"] = paddingTop;
  }

  if (typeof paddingBottom === "number") {
    style["paddingBottom"] = `${paddingBottom}px`;
  }
  if (typeof paddingBottom === "string") {
    style["paddingBottom"] = paddingBottom;
  }

  if (typeof paddingLeft === "number") {
    style["paddingLeft"] = `${paddingLeft}px`;
  }
  if (typeof paddingLeft === "string") {
    style["paddingLeft"] = paddingLeft;
  }

  if (typeof paddingRight === "number") {
    style["paddingRight"] = `${paddingRight}px`;
  }
  if (typeof paddingRight === "string") {
    style["paddingRight"] = paddingRight;
  }

  if (sameLine) {
    style["display"] = "flex";
    style["flexDirection"] = "row";
  }
  if (displayFlex) {
    style["display"] = "flex";
  }
  if (justifyContentCenter) {
    style["justifyContent"] = "center";
  }
  if (spaceBetween) {
    style["justifyContent"] = "space-between";
  }
  if (alignCenter) {
    style["alignItems"] = "center";
  }
  if (displayColumn) {
    style["display"] = "flex";
    style["flexDirection"] = "column";
  }
  if (fullWidth) {
    style["width"] = "100%";
  }
  if (alignStart) {
    style["justifyContent"] = "flex-start";
  }
  if (typeof padding === "number") {
    style["padding"] = `${padding}px`;
  }
  if (typeof padding === "string") {
    style["padding"] = padding;
  }

  if (position) {
    style["position"] = position;
  }

  if (textColor) {
    const found = colors.text[textColor];
    if (found) {
      style["color"] = found;
    }
  }

  if (boxShadow) {
    const found = boxShadows[boxShadow];
    if (found) {
      style["boxShadow"] = found;
    }
  }

  if (backgroundColor) {
    const found = colors.background[backgroundColor];
    if (found) {
      style["backgroundColor"] = found;
    }
  }

  if (borderColor) {
    const found = colors.borders[borderColor];
    if (found) {
      style["borderColor"] = found;
    }
  }

  if (capitalize) {
    style["textTransform"] = "capitalize";
  }

  if (noShadow) {
    style["boxShadow"] = "none";
  }

  if (justifyContentStart) {
    style["justifyContent"] = "flex-start";
  }

  return {
    sx: { ...sx, ...style },
  };
};

export const textEllipsis = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};
