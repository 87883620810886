export function formatDate(input: string | number): string {
  const date = new Date(input);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function absoluteUrl(path: string) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`;
}

export enum ErrorCode {
  CollectionNotFound = "CollectionNotFound",
  UserCanNotHaveFreeTrial = "UserCanNotHaveFreeTrial",
  RateLimitError = "RateLimitError",
  NotEnoughCredits = "NotEnoughCredits",
  EmailKeyExpired = "EmailKeyExpired",
  OpenAIRateLimitError = "OpenAIRateLimitError",
  FreeLimitReached = "FreeLimitReached",
  WebUrlInaccessible = "WebUrlInaccessible",
  InvalidShopifyURLError = "InvalidShopifyURLError",
  InvalidShopifyCredentialsError = "InvalidShopifyCredentialsError",
  InvalidShopifyPermissionsError = "InvalidShopifyPermissionsError",
  InvalidWordpressPermissionsError = "InvalidWordpressPermissionsError",
  MaxTeamSizeLimitReachedError = "MaxTeamSizeLimitReachedError",
  TooManyAccountsCreatedError = "TooManyAccountsCreatedError",
}

export class WebUrlInaccessible extends Error {
  status: number;
  expose?: boolean;
  code: ErrorCode;
  data: { urls: string[] };
  constructor(message: string, urls: string[] = []) {
    super(message);
    this.code = ErrorCode.WebUrlInaccessible;
    this.name = "WebUrlInaccessible";
    this.status = 422;
    this.expose = true;
    this.data = { urls };
  }
}

export class TooManyAccountsCreatedError extends Error {
  status: number;
  expose?: boolean;
  code: ErrorCode;
  constructor(message: string) {
    super(message);
    this.name = "TooManyAccountsCreatedError";
    this.status = 429;
    this.expose = true;
    this.code = ErrorCode.TooManyAccountsCreatedError;
  }
}

export class TooManyRequestError extends Error {
  status: number;
  expose?: boolean;
  code: ErrorCode;

  constructor(message: string) {
    super(message);
    this.name = "TooManyRequestError";
    this.status = 429;
    this.expose = true;
    this.code = ErrorCode.RateLimitError;
  }
}

export class RateLimitError extends Error {
  status: number;
  expose?: boolean;
  code: ErrorCode;
  data: {
    remainingTime: string | number;
  };
  constructor(message: string, remainingTime: string | number) {
    super(message);
    this.name = "RestorerError";
    this.status = 429;
    this.expose = true;
    this.code = ErrorCode.RateLimitError;
    this.data = {
      remainingTime: remainingTime,
    };
  }
}

export class EmailKeyExpiredError extends Error {
  status: number;
  expose?: boolean;
  code: ErrorCode;

  constructor(message: string) {
    super(message);
    this.name = "EmailKeyExpiredError";
    this.status = 429;
    this.expose = true;
    this.code = ErrorCode.EmailKeyExpired;
  }
}

export class NotFoundError extends Error {
  status: number;
  code?: ErrorCode;
  expose?: boolean;
  constructor(message: string, code?: ErrorCode) {
    super(message);
    this.name = "NotFoundError";
    this.code = code;
    this.status = 404;
    this.expose = true;
  }
}

export class UserCanNotHaveFreeTrialError extends Error {
  status: number;
  code?: ErrorCode;
  expose?: boolean;
  constructor(message: string) {
    super(message);
    this.name = "UserCanNotHaveFreeTrialError";
    this.status = 429;
    this.code = ErrorCode.UserCanNotHaveFreeTrial;
    this.expose = true;
  }
}

export class OpenAIRateLimitError extends Error {
  status: number;
  code?: ErrorCode;
  expose?: boolean;
  constructor(message: string) {
    super(message);
    this.name = "OpenAIRateLimitError";
    this.status = 429;
    this.code = ErrorCode.OpenAIRateLimitError;
    this.expose = false;
  }
}

export class NotEnoughCredits extends Error {
  status: number;
  code?: ErrorCode;
  expose?: boolean;
  constructor(message: string) {
    super(message);
    this.name = "NotEnoughCredits";
    this.status = 429;
    this.code = ErrorCode.NotEnoughCredits;
    this.expose = true;
  }
}

export class FreeLimitReached extends Error {
  status: number;
  code?: ErrorCode;
  expose?: boolean;
  constructor(message: string) {
    super(message);
    this.name = "FreeLimitReached";
    this.status = 429;
    this.code = ErrorCode.FreeLimitReached;
    this.expose = true;
  }
}

export class BadRequestError extends Error {
  status: number;
  expose?: boolean;
  constructor(message: string) {
    super(message);
    this.name = "BadRequestError";
    this.status = 400;
    this.expose = true;
  }
}

export class InvalidShopifyURLError extends Error {
  status: number;
  expose?: boolean;
  code?: ErrorCode;
  constructor(message: string) {
    super(message);
    this.name = "InvalidShopifyURLError";
    this.status = 400;
    this.expose = true;
    this.code = ErrorCode.InvalidShopifyURLError;
  }
}

export class InvalidShopifyCredentialsError extends Error {
  status: number;
  expose?: boolean;
  code?: ErrorCode;
  constructor(message: string) {
    super(message);
    this.name = "InvalidShopifyCredentialsError";
    this.status = 400;
    this.expose = true;
    this.code = ErrorCode.InvalidShopifyCredentialsError;
  }
}

export class InvalidShopifyPermissionsError extends Error {
  status: number;
  expose?: boolean;
  code?: ErrorCode;
  constructor(message: string) {
    super(message);
    this.name = "InvalidShopifyPermissions";
    this.status = 400;
    this.expose = true;
    this.code = ErrorCode.InvalidShopifyPermissionsError;
  }
}

export class InvalidWordpressPermissionsError extends Error {
  status: number;
  expose?: boolean;
  code?: ErrorCode;
  constructor(message: string) {
    super(message);
    this.name = "InvalidWordpressPermissionsError";
    this.status = 400;
    this.expose = true;
    this.code = ErrorCode.InvalidWordpressPermissionsError;
  }
}

export class AvjValidationError extends Error {
  status: number;
  code?: ErrorCode;
  expose?: boolean;
  constructor(message: string) {
    super(message);
    this.name = "AvjValidationError";
    this.status = 400;
    this.expose = true;
  }
}

export class ForbiddenError extends Error {
  status: number;
  expose?: boolean;
  constructor(message: string) {
    super(message);
    this.name = "Forbidden";
    this.status = 403;
    this.expose = true;
  }
}

export class NotAuthorizeError extends Error {
  status: number;
  expose?: boolean;
  constructor(message: string) {
    super(message);
    this.name = "NotAuthorize";
    this.status = 401;
    this.expose = true;
  }
}

export class ContentfulError extends Error {
  status: number;
  expose?: boolean;
  constructor(message: string) {
    super(message);
    this.name = "ContentfulError";
    this.status = 422;
    this.expose = true;
  }
}

export class ProcessOneTimePaymentError extends Error {
  status: number;
  constructor(message: string) {
    super(message);
    this.name = "ProcessOneTimePaymentError";
    this.status = 429;
  }
}

export class ProcessSubscriptionError extends Error {
  status: number;
  constructor(message: string) {
    super(message);
    this.name = "ProcessSubscriptionError";
    this.status = 429;
  }
}

export class TokenExceededError extends Error {
  status: number;
  expose?: boolean;

  constructor(message: string) {
    super(message);
    this.name = "TokenExceededError";
    this.status = 400;
    this.expose = false;
  }
}

export class MaxTeamSizeLimitReachedError extends Error {
  status: number;
  expose?: boolean;
  code: ErrorCode;

  constructor(message: string) {
    super(message);
    this.name = "MaxTeamSizeLimitReachedError";
    this.status = 429;
    this.expose = true;
    this.code = ErrorCode.MaxTeamSizeLimitReachedError;
  }
}
