import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import type { Author } from "client-server-shared/types/types";

export const authorsAdapter = createEntityAdapter<Author>({
  selectId: (author) => author.clientId,
});

export const authorsSlice = createSlice({
  name: "authors",
  initialState: authorsAdapter.getInitialState(),
  reducers: {
    addAuthors: authorsAdapter.upsertMany,
    editAuthorById: (
      state,
      action: PayloadAction<{ authorId: string; update: Partial<Author> }>
    ) => {
      const { authorId, update } = action.payload;
      const entity = state.entities[authorId];

      if (entity) {
        for (const key in update) {
          if (entity[key]) {
            entity[key] = update[key];
          }
        }
      }
      return state;
    },
    deleteAuthorById: (state, action: PayloadAction<string>) => {
      return authorsAdapter.removeOne(state, action.payload);
    },
  },
});

export const { addAuthors, editAuthorById, deleteAuthorById } =
  authorsSlice.actions;

export default authorsSlice.reducer;
