import { dashboardRoutesValues } from "client-server-shared/config/routes";
import EventEmitter from "eventemitter3";

export const authExpiredEventEmitter = new EventEmitter();

export const authExpiredId = "auth-expired";

export const onClientAuthExpired = () => {
  if (typeof window !== "undefined") {
    const pathName = window.location.pathname;

    //authRedirectRoutes.find((path) => pathName.startsWith(path))
    const isInProtectedRoute = dashboardRoutesValues.find((path) =>
      pathName.startsWith(path)
    );
    if (isInProtectedRoute) {
      try {
        authExpiredEventEmitter.emit(authExpiredId);
      } catch (e) {}
    }
  }
};
