
export enum LoadingStatus {
    Idle = "idle",
    Loading = "loading",
    Success = "success",
    Failure = "failure",
  }
export interface ProcessingLog {
  id: string;
  status: LoadingStatus;
}
