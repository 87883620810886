import { sendEvent, track, trackWithLimitedTime } from "./tracker";
import { SourceType } from "./type";
import { getGlobalActivePlan } from "../../constants";
import { interactiveTextOperation } from "client-server-shared/constants";

export const trackI18nLanguageSwitching = async () => {
  await track("i18n_language_clicked");
};

export const trackClickOnFaq = async ({ question }: { question: string }) => {
  await trackWithLimitedTime("click_on_faq", {
    question,
  });
};

export const multipleAccounts = async (userIds: string[]) => {
  await trackWithLimitedTime("multiple_accounts", {
    userIds: userIds.join(","),
  });
};

export const trackOpenReWriteLanguagesModel = async () => {
  await trackWithLimitedTime("open_rewrite_languages");
};

export const trackClickOnRewriteLanguage = async () => {
  await trackWithLimitedTime("click_on_rewrite_language");
};
export const trackEditorInteractiveCommand = async (type: string) => {
  if (
    // todos...
    type === interactiveTextOperation.writeMore ||
    type === interactiveTextOperation.command ||
    type === "generate-images"
  ) {
    return;
  }
  const globalActivePlan = getGlobalActivePlan();
  await trackWithLimitedTime("use_editor_command", {
    type: type,
    plan: globalActivePlan?.planName || "free",
  });
};

export const trackClickOnHowToUse = async ({
  component,
  source,
}: {
  component: string;
  source: SourceType;
}) => {
  await trackWithLimitedTime("click_on_how_to_use", {
    component,
    source,
  });
};

export const trackUnsubscribe = async ({ type }: { type: string }) => {
  await trackWithLimitedTime("unsubscribe", {
    type,
  });
};
export const trackResubscribe = async ({ type }: { type: string }) => {
  await trackWithLimitedTime("resubscribe", {
    type,
  });
};

export const trackClickOnSocialMediaLink = async ({
  type,
}: {
  type: string;
}) => {
  await trackWithLimitedTime("click_on_social_media_link");
};

export const trackUseKeywordResearch = async () => {
  await trackWithLimitedTime("use_keyword_explorer");
};

export const contentAuditUse = async ({ type }: { type: string }) => {
  await trackWithLimitedTime("content_audit_use", {
    type: type,
  });
};

export const trackRevealTerms = async ({ source }: { source: SourceType }) => {
  await trackWithLimitedTime("reveal_terms", {
    source: source,
  });
};

export const trackBulkCreation = async ({
  amount,
  mode,
}: {
  amount: number;
  mode: string;
}) => {
  await sendEvent("bulk_creation", {
    amount,
    mode,
  });
};
