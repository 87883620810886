export const defaultLocation = "United States";
export const locationData = [
  {
    name: "Australia",
    code: "AU",
    id: "2036",
    languages: [],
    capital: "Canberra",
  },
  {
    name: "Austria",
    code: "AT",
    id: "2040",
    languages: ["1001"],
    capital: "Vienna",
  },
  {
    name: "Argentina",
    code: "AR",
    id: "2032",
    languages: ["1003"],
    capital: "Buenos Aires",
  },
  {
    name: "Belgium",
    code: "BE",
    id: "2056",
    languages: ["1010", "1002"],
    capital: "Brussels",
  },
  {
    name: "Bulgaria",
    code: "BG",
    id: "2100",
    languages: ["1020"],
    capital: "Sofia",
  },
  {
    name: "Brazil",
    code: "BR",
    id: "20106",
    languages: ["1014"],
    capital: "Brasília",
  },
  {
    name: "Canada",
    code: "CA",
    id: "2124",
    languages: [],
    capital: "Ottawa",
  },
  {
    name: "Croatia",
    code: "HR",
    id: "21493",
    languages: ["1039"],
    capital: "Zagreb",
  },
  {
    name: "Cyprus",
    code: "CY",
    id: "2196",
    languages: [],
    capital: "Nicosia",
  },
  {
    name: "Czech Republic",
    code: "CZ",
    id: "2203",
    languages: ["1021"],
    capital: "Prague",
  },
  {
    name: "Denmark",
    code: "DK",
    id: "2208",
    languages: ["1009"],
    capital: "Copenhagen",
  },
  {
    name: "Estonia",
    code: "EE",
    id: "2233",
    languages: ["1043"],
    capital: "Tallinn",
  },
  {
    name: "Egypt",
    code: "EG",
    id: "21469",
    languages: ["1019"],
    capital: "Cairo",
  },
  {
    name: "Finland",
    code: "FI",
    id: "2246",
    languages: ["1011"],
    capital: "Helsinki",
  },
  {
    name: "France",
    code: "FR",
    id: "20321",
    languages: ["1002"],
    capital: "Paris",
  },
  {
    name: "Germany",
    code: "DE",
    id: "2276",
    languages: ["1001"],
    capital: "Berlin",
  },
  {
    name: "Greece",
    code: "GR",
    id: "9075555",
    languages: ["1022"],
    capital: "Athens",
  },
  {
    name: "Hong Kong",
    code: "HK",
    id: "2344",
    languages: [],
    capital: "Hong Kong",
  },
  {
    name: "Hungary",
    code: "HU",
    id: "20418",
    languages: ["1024"],
    capital: "Budapest",
  },
  {
    name: "Iceland",
    code: "IS",
    id: "2352",
    languages: ["1026"],
    capital: "Reykjavik",
  },
  {
    name: "India",
    code: "IN",
    id: "2356",
    languages: [],
    capital: "New Delhi",
  },
  {
    name: "Indonesia",
    code: "ID",
    id: "2360",
    languages: ["1025"],
    capital: "Jakarta",
  },
  {
    name: "Ireland",
    code: "IE",
    id: "9069530",
    languages: [],
    capital: "Dublin",
  },
  {
    name: "Israel",
    code: "IL",
    id: "2376",
    languages: ["1027"],
    capital: "Jerusalem",
  },
  {
    name: "Italy",
    code: "IT",
    id: "2380",
    languages: ["1004"],
    capital: "Rome",
  },
  {
    name: "Japan",
    code: "JP",
    id: "20636",
    languages: ["1005"],
    capital: "Tokyo",
  },
  {
    name: "Latvia",
    code: "LV",
    id: "9062318",
    languages: ["1028"],
    capital: "Riga",
  },
  {
    name: "Lithuania",
    code: "LT",
    id: "9062294",
    languages: ["1029"],
    capital: "Vilnius",
  },
  {
    name: "Luxembourg",
    code: "LU",
    id: "2442",
    languages: [],
    capital: "Luxembourg",
  },
  {
    name: "Malta",
    code: "MT",
    id: "2470",
    languages: [],
    capital: "Valletta",
  },
  {
    name: "Mexico",
    code: "MX",
    id: "2484",
    languages: [],
    capital: "Mexico City",
  },
  {
    name: "Malaysia",
    code: "MY",
    id: "20751",
    languages: [],
    capital: "Kuala Lumpur",
  },
  {
    name: "Netherlands",
    code: "NL",
    id: "20763",
    languages: ["1010"],
    capital: "Amsterdam",
  },
  {
    name: "New Zealand",
    code: "NZ",
    id: "2554",
    languages: [],
    capital: "Wellington",
  },
  {
    name: "Norway",
    code: "NO",
    id: "20773",
    languages: ["1013"],
    capital: "Oslo",
  },
  {
    name: "Poland",
    code: "PL",
    id: "2616",
    languages: ["1030"],
    capital: "Warsaw",
  },
  {
    name: "Portugal",
    code: "PT",
    id: "2620",
    languages: ["1014"],
    capital: "Lisbon",
  },
  {
    name: "Qatar",
    code: "QA",
    id: "9069542",
    languages: ["1019"],
    capital: "Doha",
  },
  {
    name: "Romania",
    code: "RO",
    id: "20889",
    languages: ["1032"],
    capital: "Bucharest",
  },
  {
    name: "Russia",
    code: "RU",
    id: "2643",
    languages: ["1031"],
    capital: "Moscow",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    id: "20987",
    languages: ["1019"],
    capital: "Riyadh",
  },
  {
    name: "Slovakia",
    code: "SK",
    id: "2703",
    languages: ["1033"],
    capital: "Bratislava",
  },
  {
    name: "Slovenia",
    code: "SI",
    id: "2705",
    languages: ["1034"],
    capital: "Ljubljana",
  },
  {
    name: "South Africa",
    code: "ZA",
    id: "21222",
    languages: [],
    capital: "Cape Town",
  },
  {
    name: "South Korea",
    code: "KR",
    id: "2410",
    languages: ["1012"],
    capital: "Seoul",
  },
  {
    name: "Singapore",
    code: "SG",
    id: "2702",
    languages: [],
    capital: "Singapore",
  },
  {
    name: "Spain",
    code: "ES",
    id: "20282",
    languages: ["1003"],
    capital: "Madrid",
  },
  {
    name: "Sweden",
    code: "SE",
    id: "21000",
    languages: ["1015"],
    capital: "Stockholm",
  },
  {
    name: "Switzerland",
    code: "CH",
    id: "20129",
    languages: [],
    capital: "Bern",
  },
  {
    name: "Taiwan",
    code: "TW",
    id: "2158",
    languages: ["1018"],
    capital: "Taipei City",
  },
  {
    name: "Thailand",
    code: "TH",
    id: "2764",
    languages: ["1044"],
    capital: "Bangkok",
  },
  {
    name: "Turkey",
    code: "TR",
    id: "2792",
    languages: ["1037"],
    capital: "Ankara",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    id: "2784",
    languages: ["1019"],
    capital: "Abu Dhabi",
  },
  {
    name: "United Kingdom",
    code: "GB",
    id: "20339",
    languages: [],
    capital: "London",
  },
  {
    name: "United States",
    code: "US",
    id: "2840",
    languages: [],
    capital: "Washington",
  },
  {
    name: "Vietnam",
    code: "VN",
    id: "2704",
    languages: ["1040"],
    capital: "Hanoi",
  },
];
