import React from "react";
import { IconButton, IconButtonProps } from "./ui/button";
import { BoxProps } from "./ui/box";

export const IconComponent = React.forwardRef(({ icon, ...rest }, ref) => {
  const Icon = icon;
  return (
    <Icon
      ref={ref}
      {...rest}
      sx={{
        height: "14px",
        width: "14px",
        minWidth: rest.sx?.width || "14px",
        minHeight: rest.sx?.height || "14px",
        fill: "rgba(0, 0, 0, 0.5)",
        ...(rest.sx || {}),
      }}
    />
  );
});

/*

export const IconButtonInGrayBox = React.forwardRef(
  (
    {
      icon,
      iconProps = {},
      ...rest
    }: IconButtonProps & { iconProps?: BoxProps },
    ref
  ) => {
    return (
      <IconButton {...rest}>
        <IconComponent
          icon={icon}
          {...iconProps}
          sx={{
            ...(iconProps.sx || {}),
          }}
        />
      </IconButton>
    );
  }
);
*/
