import MuiAvatar, { AvatarProps } from "@mui/material/Avatar";
import { getStyleProps, passProps } from "modules/themes/styleUtils";
import React from "react";

export const Avatar = React.forwardRef((props: AvatarProps, ref) => {
  const { children, ...rest } = props;

  return (
    <MuiAvatar ref={ref} {...passProps(rest)} {...getStyleProps(rest)}>
      {children}
    </MuiAvatar>
  );
});

Avatar.displayName = "Avatar";
