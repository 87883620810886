import React from "react";

import { Button } from "components/ui/button";
import { Typography } from "components/ui/Typography";
import { NextLink } from "components/next-link";
import { Box } from "components/ui/box";
import { ListItem } from "components/ui/list/List";
import Popper from "@mui/material/Popper";
import { Paper } from "components/ui/paper";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { useShortId } from "hooks/use-short-id";
import { IconComponent } from "components/icon-component";
import Fade from "@mui/material/Fade";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

export default function Submenu({ title, items, icon }) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const ref = React.useRef<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!anchorEl) {
      setAnchorEl(ref.current);
    }
  };
  const id = useShortId();

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const containerRef = React.useRef();

  const open = Boolean(anchorEl);

  return (
    <Box
      ref={containerRef}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Button
        capitalize
        ref={ref}
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        endIcon={
          <IconComponent
            sx={{
              fill: "white",
              height: "20px",
              width: "20px",
              transition: "all 0.3s ease",
              transform: !open ? "rotate(180deg)" : "rotate(0deg)",
            }}
            icon={KeyboardArrowUpOutlinedIcon}
          />
        }
        sx={{
          my: 2,
          display: "flex",
          textAlign: "center",
          color: "#D4DCE5",
          fontSize: "14px",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Button>
      <Popper
        id={id}
        open={open}
        keepMounted
        transition
        container={containerRef.current}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              component="nav"
              sx={{
                padding: "12px 12px",
                margin: "14px 0 0 0",
                width: "250px",
                borderRadius: "10px",
                position: "relative",

                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                "&:before": {
                  position: "absolute",
                  content: "''",
                  top: "-6px",
                  right: "50%",
                  transform: "translateX(50%)",
                  borderLeft: "12px solid transparent",
                  borderRight: "12px solid transparent",
                  borderBottom: "12px solid white",
                },
              }}
            >
              <Box
                component="ul"
                sx={{
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "12px",
                }}
              >
                {items.map((option, index) => {
                  return (
                    <ListItem
                      key={index}
                      sx={{
                        padding: "3px 6px",
                      }}
                    >
                      <OutlineGreyButton
                        asText
                        component={NextLink}
                        href={option.to}
                        onClick={() => {}}
                        {...(option.linkProps || {})}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          "& span": {
                            width: "100%",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            columnGap: "16px",
                          }}
                        >
                          <IconComponent
                            sx={{
                              height: "24px",
                              width: "24px",
                            }}
                            icon={option.icon}
                          />
                          <Typography
                            sx={{
                              fontWeight: 600,
                              color: "#333",
                            }}
                            variant="body2"
                          >
                            {option.title}
                          </Typography>
                        </Box>
                      </OutlineGreyButton>
                    </ListItem>
                  );
                })}
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}
