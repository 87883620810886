import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ImageFile,
  InfoBaseItem,
  Prompt,
  SourceFile,
  Style,
} from "client-server-shared/types";
import { omit } from "client-server-shared/utils/lodash-methods";
import { hydrate } from "../actions";
import { ConnectedWebsitesResponse } from "client-server-shared/types/connected-website";

interface Settings {
  contentful: {
    accessToken: boolean;
    spaceId: string | null;
    environment: string | null;
    contentType: any | null;
  };
  clientSettings: {};
  hydrated: boolean;
  styles: Style[];
  files: SourceFile[];
  images: ImageFile[] | null;
  infobase: InfoBaseItem[];
  prompts: Prompt[];
  websites: ConnectedWebsitesResponse | null;
}

const initialState: Settings = {
  contentful: {
    accessToken: true,
    spaceId: null,
    environment: null,
    contentType: null,
  },
  clientSettings: {},
  styles: [],
  files: [],
  infobase: [],
  images: null,
  hydrated: false,
  prompts: [],
  websites: null,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateContentfulConfig: (
      state,
      action: PayloadAction<Partial<Settings["contentful"]>>
    ) => {
      state.contentful = { ...state.contentful, ...action.payload };
      return state;
    },
    resetContentfulConfig: (state) => {
      state.contentful = initialState.contentful;
    },
    updateClientSettings: (
      state,
      action: PayloadAction<
        Partial<Settings["clientSettings"] & { collectionId?: string }>
      >
    ) => {
      state.clientSettings = omit(
        { ...state.clientSettings, ...action.payload },
        ["collectionId"]
      );
    },
    deleteStyle: (state, action: PayloadAction<string>) => {
      state.styles = state.styles.filter(
        (style) => style.id !== action.payload
      );
    },
    updateStyle: (state, action: PayloadAction<Style>) => {
      const index = state.styles.findIndex(
        (style) => style.id === action.payload.id
      );
      if (index !== -1) {
        state.styles[index] = {
          ...state.styles[index],
          ...action.payload,
        };
      }
    },
    setStyles: (state, action: PayloadAction<Style[]>) => {
      state.styles = action.payload;
    },
    addStyles: (state, action: PayloadAction<Style[]>) => {
      state.styles.unshift(...action.payload);
    },
    deletePrompt: (state, action: PayloadAction<string>) => {
      state.prompts = state.prompts.filter(
        (prompt) => prompt.id !== action.payload
      );
    },
    updatePrompt: (state, action: PayloadAction<Prompt>) => {
      const index = state.prompts.findIndex(
        (prompt) => prompt.id === action.payload.id
      );
      if (index !== -1) {
        state.prompts[index] = {
          ...state.prompts[index],
          ...action.payload,
        };
      }
    },
    setPrompts: (state, action: PayloadAction<Prompt[]>) => {
      state.prompts = action.payload;
    },
    addPrompts: (state, action: PayloadAction<Prompt[]>) => {
      state.prompts.unshift(...action.payload);
    },
    setSourceFiles: (state, action: PayloadAction<SourceFile[]>) => {
      state.files = action.payload;
    },
    addFiles: (state, action: PayloadAction<SourceFile[]>) => {
      state.files.unshift(...action.payload);
    },
    removeFile: (state, action: PayloadAction<string>) => {
      state.files = state.files.filter((file) => file.id !== action.payload);
    },
    updateFile: (state, action: PayloadAction<SourceFile>) => {
      const index = state.files.findIndex(
        (file) => file.id === action.payload.id
      );
      if (index !== -1) {
        state.files[index] = {
          ...state.files[index],
          ...action.payload,
        };
      }
    },
    setImages: (state, action: PayloadAction<ImageFile[]>) => {
      state.images = action.payload;
    },
    deleteImage: (state, action: PayloadAction<string>) => {
      state.images = (state.images || []).filter(
        (image) => image.path !== action.payload
      );
    },
    addImages: (state, action: PayloadAction<ImageFile[]>) => {
      if (!state.images) {
        state.images = action.payload;
      } else {
        state.images.push(...action.payload);
      }
    },
    setWebsites: (state, action: PayloadAction<ConnectedWebsitesResponse>) => {
      state.websites = action.payload;
    },
    deleteDomain: (state, action: PayloadAction<string>) => {
      if (state.websites) {
        const newWebsites = { ...state.websites };
        delete newWebsites[action.payload];
        state.websites = newWebsites;
      }
    },
    deleteWebsite: (
      state,
      action: PayloadAction<{
        domainUrl: string;
        id: string;
      }>
    ) => {
      if (state.websites) {
        const foundDomain = state.websites[action.payload.domainUrl];
        if (foundDomain) {
          const newWebsites = foundDomain.websites.filter(
            (website) => website.id !== action.payload.id
          );
          state.websites[action.payload.domainUrl].websites = newWebsites;
        }
        for (const domain in state.websites) {
          if (state.websites[domain].websites.length === 0) {
            delete state.websites[domain];
          }
        }
      }
    },
    onHydrated: (state) => {
      state.hydrated = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (state.styles.length > 0 || state.files.length > 0 || state.images) {
        return state;
      }
      return action.payload.settings;
    });
  },
});

export const {
  updateContentfulConfig,
  resetContentfulConfig,
  updateClientSettings,
  addStyles,
  onHydrated,
  updateStyle,
  setStyles,
  deleteStyle,
  setSourceFiles,
  addFiles,
  updateFile,
  removeFile,
  setImages,
  deleteImage,
  addImages,
  deletePrompt,
  updatePrompt,
  setPrompts,
  addPrompts,
  setWebsites,
  deleteDomain,
  deleteWebsite,
} = settingsSlice.actions;

export default settingsSlice.reducer;
