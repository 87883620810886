import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { hydrate } from "../actions";

interface CollectionExtraState {
  hasMore: boolean | null;
}

const initiaState: CollectionExtraState = {
  hasMore: true,
};

export const collectionExtra = createSlice({
  name: "collectionExtra",
  initialState: initiaState,
  reducers: {
    setHasMore: (state, action: PayloadAction<boolean>) => {
      state.hasMore = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (state.hasMore !== null) {
        return state;
      }
      return action.payload.collectionExtra;
    });
  },
});

export const { setHasMore } = collectionExtra.actions;

export default collectionExtra.reducer;
