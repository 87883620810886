import MUBox, { BoxProps as MuiBoxProps } from "@mui/material/Box";
import { ExtendedStyleProps } from "modules/themes/custom-types";
import { getStyleProps, passProps } from "modules/themes/styleUtils";
import React from "react";
export type BoxProps = any;

export const Box = React.forwardRef(
  (props: BoxProps & ExtendedStyleProps, ref) => {
    const { children, ...rest } = props;
    return (
      <MUBox {...passProps(rest)} ref={ref} {...getStyleProps(rest)}>
        {children}
      </MUBox>
    );
  }
);

Box.displayName = "Box";
