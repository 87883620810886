import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { hydrate } from "../actions";
import { FolderExtra } from "./types";
import { folderDeleted } from "./action";

export const folderExtraAdapter = createEntityAdapter<FolderExtra>({
  selectId: (log) => log.id,
});

export const foldersExtraSlice = createSlice({
  name: "folders-extra",
  initialState: folderExtraAdapter.getInitialState(),
  reducers: {
    folderSetHasMore: (
      state,
      action: PayloadAction<
        {
          id: string;
          hasMore: boolean;
        }[]
      >
    ) => {
      return folderExtraAdapter.upsertMany(state, action.payload);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (state.ids.length > 0) {
        return state;
      }
      return action.payload.foldersExtra;
    });
  },
});

export const { folderSetHasMore } = foldersExtraSlice.actions;

export default foldersExtraSlice.reducer;
