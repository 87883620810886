import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { hydrate } from "../actions";
import { Folder } from "./types";
import { folderDeleted } from "./action";

export const folderAdapter = createEntityAdapter<Folder>({
  selectId: (log) => log.id,
  sortComparer: (a, b) =>
    new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
});

export const foldersSlice = createSlice({
  name: "folders",
  initialState: folderAdapter.getInitialState(),
  reducers: {
    foldersAdded: (state, action: PayloadAction<Folder[]>) => {
      return folderAdapter.upsertMany(
        state,
        action.payload.map((folder) => {
          return {
            ...folder,
            document_counts: folder.document_counts || 0,
          };
        })
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (state.ids.length > 0) {
        return state;
      }
      return action.payload.folders;
    });
    builder.addCase(folderDeleted, (state, action) => {
      return folderAdapter.removeOne(state, action.payload);
    });
  },
});

export const { foldersAdded } = foldersSlice.actions;

export default foldersSlice.reducer;
