import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { hydrate } from "./actions";

interface TemplateTranslation {
  data: Record<string, string> | null;
}

const initialState: TemplateTranslation = {
  data: null,
};

export const templateTranslationSlice = createSlice({
  name: "templateTranslationSlice",
  initialState,
  reducers: {
    translationLoaded: (
      state,
      action: PayloadAction<TemplateTranslation["data"]>
    ) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (state.data) {
        return state;
      }
      return action.payload.templateTranslations;
    });
  },
});

export const { translationLoaded } = templateTranslationSlice.actions;

export default templateTranslationSlice.reducer;
