import React from "react";
import {
  useTranslation as originalUseTranslation,
  Trans as OriginlTrans,
} from "next-i18next";

const editorTranslations = {
  lengthen_text: "Write more",
  translate_text: "Translate",
  paraphrase_text: "Paraphrase",
  error_enhancing_text:
    "There's an error with Junia when enhancing your text, sorry about that! Please try again later.",
  error_enhancing_text_not_enough_credits:
    "You have run out of credits. Please purchase more credits to continue using Junia.",

  error_no_selected_text: "Please select some text first",

  interactive_more_menu_button: "More shortcut",
  interactive_lengthen_text: "Write more",
  interactive_translate_text: "Translate",
  interactive_paraphrase_text: "Paraphrase",
  interactive_synonyms_text: "Synonyms",

  interactive_shorten_text: "Shorten",
  interactive_summarize_text: "Summarize",
  interactive_write_outline_text: "Write an outline",
  interactive_input_label: "Junia chat input",
  interactive_input_placeholder:
    "Need help editing or rewriting? Ask me anything!",
  interactive_paid_plan_only: "Paid plan only",
};

const translations = {
  cancel: "Cancel",
  confirm: "Confirm",
  confirm_your_selection: "Review your selection",
  generate_button_text: "Generate",
  cancel_button_text: "Cancel",
  add_button_text: "Add",
  generate_button_tooltip: "Generate with AI",
  add_button_tooltip: "Add without AI",
  add_article_titles_section_text: "Titles",
  add_article_titles_info: "Add multiple titles for bulk generation",
  add_keywords_section_text: "Keywords/Topics",
  add_keywords_info: "Add multiple keywords or topics for bulk generation",
  add_purpose_section_text: "Purpose",
  add_purpose_section_info: "What's the purpose of the generated content?",
  add_purpose_input_placeholder: "To drive more traffic to my websites",
  unique_toggle_section_text: "Unique",
  unique_toggle_section_info:
    "Encourage Eva to generate unique content that's different from your existing collections",
  unique_toggle_section_tooltip:
    "Will take more time as Eva will need to summaize all your existing content",
  unique_toggle_section_import_text: "Import external content",
  unique_toggle_section_import_help_text:
    "Choose this if you have content that you wish to avoid the duplication of but lives in other platforms",
  type_and_press_enter_to_add: "Type and press enter to add",
  type_and_press_enter_to_add_titles: "Type and press enter to add titles",
  smart_mode: "Smart Mode",
  free_form: "Free Form",
  free_form_description_article:
    "Each article title will be generated as exactly one article",
  smart_mode_description_article:
    "Enter keywords, topics, purposes and let AI decide what to generate",
  variations_count_info_per_article:
    "Genereate multiple variations for quick content ideation",
  amount_section_text_article: "Article amount",
  amount_section_help_text_article:
    "Amount of articles to generate based on your input keywords, between 1 to 100",
  select_image_section_text: "Select Image",
  start_upload_now: "Start Upload",
  enter_a_url: "Enter a URL",
  image_drag_and_drop_help_text:
    "Drag and drop a file to upload, or click to select a file",
  drag_and_drop_help_text:
    "Drag and drop a file to upload, or click to select a file",
  secondary_file_upload_support_files:
    "Supports files: .pdf, .docx, .txt, .md, .mdoc, .html, .mdx, up to 10mb in size",
  primary_file_upload_support_files:
    "Supports files: .pdf, .docx, .txt, .md, .mdoc, .html, .mdx, up to 2mb in size",
  upload_sidebar_label_local: "My Device",
  upload_sidebar_label_link: "Link (URL)",
  enter_collection_title: "Enter Collection Title",
  enter_post_title: "Enter Title",
  add_more_articles: "Add More Articles",
  add_more_articles_info:
    "Add more articles to your collection by entering the titles of the articles",
  add_more_articles_with_keywords_prompt:
    "Don't have the titles yet? Try start with keywords",
  here: "here",
  language: "Language",
  outputs: "Outputs",
  add_authors_to_your_selections: "Add author(s) to your selections",
  no_results_found: "No results found",
  submit_feature_request: "Submit a feature request",
  all: "all",
  home: "Dashboard",
  Templates: "Templates",
  Collections: "Collections",
  create_an_account: "Create an account",
  create_an_account_with_email_info:
    "Enter your email below to create your account",
  login: "Login",
  selecting_template_in_collection_notice:
    "The chosen template will be applied to all the articles in this collection",
  word: "word",
  ...editorTranslations,
};

export const useTranslation = (namespace?: string) => {
  const { t: originalT } = originalUseTranslation(namespace || "dashboard");
  const t = React.useCallback((key: keyof typeof translations) => {
    return translations[key] || "";
  }, []);
  return { t: originalT };
};

export const useDashboardTranslation = () => {
  const { t } = useTranslation("dashboard");
  return { t };
};

export const useCommonTranslation = () => {
  const { t } = useTranslation("common");
  return { t };
};

export const useDashboardChatTranslation = () => {
  const { t } = useTranslation("dashboard-chat");
  return { t };
};

export const useDashboardImageTranslation = () => {
  const { t } = useTranslation("dashboard-image");
  return { t };
};

export const useDashboardSettingsTranslation = () => {
  const { t } = useTranslation("dashboard-settings");
  return { t };
};

export const useCollectionsTranslation = () => {
  const { t } = useTranslation("collections");
  return { t };
};

export const Trans = OriginlTrans;
