import { countWordsV2 } from "./countWords";

export const slugify = (text) => {
  if (text) {
    const slug = text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
    if (!slug) {
      return text;
    }
    return slug;
  }

  return text || "";
};

export const strippedHtml = (text: string) => text.replace(/<[^>]+>/g, "");
export const removeImageFromHtml = (text: string) =>
  text.replace(/<img[^>]*>/g, "");

export const removeMarkdown = (str: string) => {
  return str.replace(/(^#+\s*|\*\*|\*|\~\~|\`|\[.*\]\(.*\))/gm, "");
};

export const cleanStr = (str: string) => {
  return strippedHtml(removeMarkdown(str));
};

export function getContentBeforeMiddleAndAfter(
  content: string,
  wordLimit: number = 1000 // char limits
): string {
  const cleanContent = strippedHtml(content);

  if (cleanContent.length <= wordLimit) {
    return cleanContent;
  }

  let beforeContent = cleanContent.slice(0, wordLimit / 2);
  let afterContent = cleanContent.slice(-wordLimit / 2);
  return `${beforeContent}...${afterContent}`;
}

export function getExcerpt(content: string, wordLimit: number = 500): string {
  const cleanContent = strippedHtml(content);
  const words = cleanContent.split(" ");
  if (words.length <= wordLimit) {
    return cleanContent;
  }
  return words.slice(0, wordLimit).join(" ") + "...";
}

export const getContextText = (text: string, limit = 1000) => {
  if (!text) {
    return text;
  }
  const [part1, part2, part3] = divideStringIntoThreeParts(text);

  const newText = constructText(part1, part2, part3, limit, true);

  return newText;
};

export function cleanBeforeAndAfterToLimit(
  beforeText: string,
  afterText: string,
  params: {
    beforeLimit?: number;
    ignoreBeforeLimit?: number;
    afterLimit?: number;
  }
) {
  const {
    beforeLimit = 1000,
    ignoreBeforeLimit = 0,
    afterLimit = 500,
  } = params;
  const wordLimit = 800;

  const cleanBeforeText = cleanStr(beforeText);
  const cleanAfterText = cleanStr(afterText);
  let gotBeforeText = cleanBeforeText.slice(-beforeLimit);

  gotBeforeText = gotBeforeText.slice(
    0,
    gotBeforeText.length - ignoreBeforeLimit
  );

  return `${cleanAfterText.slice(0, afterLimit)}. ${gotBeforeText}`;
}

export function getExcerptBeforeAndAfter(
  content: string,
  wordLimit: number = 800,
  startIndex: number,
  endIndex: number
) {
  const cleanContent = strippedHtml(content);
  const words = cleanContent.split(" ");
  const selectedWords = cleanContent.slice(startIndex, endIndex + 1).split(" ");
  const selectedWordCount = selectedWords.length;

  if (selectedWordCount >= wordLimit) {
    return {
      beforeWords: "",
      selectedWords: selectedWords.join(" "),
      afterWords: "",
      excerpt: "",
    };
  }

  const remainingWordLimit = wordLimit - selectedWordCount;
  let beforeWordsLimit = Math.floor(remainingWordLimit / 2);
  let afterWordsLimit = remainingWordLimit - beforeWordsLimit;

  const beforeText = cleanContent.slice(0, startIndex);
  const beforeTextWords = beforeText.split(" ");
  const beforeWords = beforeTextWords.slice(
    Math.max(0, beforeTextWords.length - beforeWordsLimit),
    beforeTextWords.length
  );

  const afterText = cleanContent.slice(endIndex + 1);
  const afterTextWords = afterText.split(" ");
  const afterWords = afterTextWords.slice(0, afterWordsLimit);

  return {
    beforeWords: beforeWords.length > 0 ? beforeWords.join(" ") : "",
    selectedWords: selectedWords.length > 0 ? selectedWords.join(" ") : "",
    afterWords: afterWords.length > 0 ? afterWords.join(" ") : "",
    excerpt: [...beforeWords, ...selectedWords, ...afterWords].join(" "),
  };
}

export function getExcerptReverse(
  content: string,
  wordLimit: number = 500
): string {
  const cleanContent = strippedHtml(content);
  const words = cleanContent.split(" ");
  if (words.length <= wordLimit) {
    return cleanContent;
  }
  return "..." + words.slice(-wordLimit).join(" ");
}

export const textToArray = (text: string) => text.split(/\s+/);

export function constructText(
  part1: string,
  part2: string,
  part3: string,
  maxWords = 4000,
  includeMiddle?: boolean
) {
  let wordsPart1 = part1 ? countWordsV2(part1) : 0;
  let wordsPart2 = part2 ? countWordsV2(part2) : 0;
  let wordsPart3 = part3 ? countWordsV2(part3) : 0;

  if (wordsPart1 + wordsPart3 <= maxWords) {
    // If there is room for part2 after inserting part1 and part3
    if (wordsPart1 + wordsPart2 + wordsPart3 <= maxWords) {
      return `${part1} ${part2} ${part3}`;
    } else {
      if (includeMiddle) {
        return `${part1}, ${part2.slice(0, 700)}, ${part3}`;
      }

      return `${part1} ${part3}`;
    }
  } else {
    let newPart1 = part1;
    if (wordsPart1 > maxWords / 2) {
      newPart1 = truncateToMaxWords(newPart1, maxWords / 2);
      // maxWords / 2
    }
    let newPart3 = part3;

    if (wordsPart3 > maxWords / 2) {
      newPart3 = truncateToMaxWords(newPart3, maxWords / 2);
    }
    if (includeMiddle) {
      return `${newPart1},${part2.slice(0, 700)},${newPart3}`;
    }
    return `${newPart1},${newPart3}`;
  }
}

export function truncateToMaxWords(text: string, maxWords: number) {
  if (!text) {
    return text;
  }
  const currentCount = countWordsV2(text);

  if (currentCount <= maxWords) {
    return text;
  }
  const limitRatio = maxWords / currentCount;

  let finalText = text;

  finalText = text.slice(0, Math.round(text.length * limitRatio));
  return finalText;
}

export function divideStringIntoThreeParts(inputString) {
  let strLength = inputString.length;
  let partLength = Math.floor(strLength / 3);

  // Calculate remainder
  let remainder = strLength % 3;

  let part1 = inputString.substring(0, partLength + (remainder > 0 ? 1 : 0));
  let part2 = inputString.substring(
    part1.length,
    part1.length + partLength + (remainder > 1 ? 1 : 0)
  );
  let part3 = inputString.substring(part1.length + part2.length);

  return [part1, part2, part3];
}
