import {
  createSlice,
  Draft,
  createEntityAdapter,
  EntityState,
  original,
  isDraft,
  AnyAction,
} from "@reduxjs/toolkit";
import {
  collectionSavedById,
  editCollectionPropertiesByCollectionId,
  editPostPropertiesByCollectionIdAndPostId,
  emptyAction,
  redo,
  undo,
  addPosts,
  removePostById,
  toggleCollectionAutoSave,
  postsLoaded,
} from "./actions";
import postReducer, { PostState } from "./post";
import { Post, Collection, Locale } from "client-server-shared/types/types";
import { defaultCollectionName } from "client-server-shared/constants";

type CollectionWithoutPosts = Omit<Collection, "posts">;

export type CollectionState = {
  unsavedChanges?: boolean;
  previous: PostState[][];
  current: EntityState<PostState>;
  next: PostState[][];
  data: CollectionWithoutPosts;
  changes?: Partial<CollectionWithoutPosts>;
};

export const postsAdapter = createEntityAdapter<PostState>({
  selectId: (postState) => postState.data.clientId,
  sortComparer: (a, b) =>
    new Date(b.data.createdAt).getTime() - new Date(a.data.createdAt).getTime(),
});

const initialPostState: PostState = {
  data: {
    id: "",
    clientId: "",
  } as Post,
  selectedContentIndex: 0,
};

const initialCollectionState: CollectionState = {
  data: {
    title: defaultCollectionName,
    id: "",
    clientId: "",
    locales: [Locale.en],
  } as CollectionWithoutPosts,
  unsavedChanges: false,
  changes: {},
  previous: [],
  next: [],
  current: postsAdapter.getInitialState(),
};

const initialState: CollectionState = {
  ...initialCollectionState,
  current: postsAdapter.getInitialState(),
};

const preLoadedCollectionState: CollectionState = {
  data: initialCollectionState.data,
  changes: {},
  previous: [],
  current: postsAdapter.getInitialState(),
  next: [],
};

export const loadCollectionState = (collection: Collection) => {
  const { posts, ...rest } = collection;
  return {
    data: rest,
    unsavedChanges: !rest.id,
    changes: {},
    previous: [],
    current: postsAdapter.upsertMany(
      initialState.current,
      collection.posts.map((post) => ({
        data: {
          ...post,
          collectionId: collection.clientId,
        },
        unsavedChanges: !post.id,
        selectedContentIndex: 0,
      }))
    ),
    next: [],
  };
};

const getInitialPostState = (post: Post, collectionId: string) => {
  const hasUnsavedChanges = !post.id;
  const initialTemplateReducerState: PostState = postReducer(
    {
      data: {
        ...post,
        collectionId,
      },
      unsavedChanges: hasUnsavedChanges,
      selectedContentIndex: 0,
    },
    emptyAction
  );
  const initialState = initialTemplateReducerState;
  return postReducer(initialState, emptyAction);
};

export const getNextPostState = (
  state: Draft<PostState>,
  action: AnyAction
) => {
  return postReducer(state, action);
};

const onAddPosts = (state: CollectionState, posts: Post[]) => {
  const collectionId = state.data.clientId;

  const postsToAdd = posts.map((postToAdd) =>
    getInitialPostState(postToAdd, collectionId)
  );
  state.current = postsAdapter.upsertMany(state.current, postsToAdd);
  return state;
};

const collectionSlice = createSlice({
  name: "collection",
  initialState: preLoadedCollectionState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addPosts, (state, action) => {
      const { posts } = action.payload;

      return onAddPosts(state, posts);
    });
    builder.addCase(removePostById, (state, action) => {
      state.current = postsAdapter.removeOne(
        state.current,
        action.payload.postId
      );
      return state;
    });
    builder.addCase(undo, (state) => {
      if (state.previous.length === 0) {
        return state;
      }

      const previousPosts = state.previous[state.previous.length - 1];
      const newPastState = state.previous.slice(0, state.previous.length - 1);

      const presentPosts: PostState[] = [];

      for (const previousPost of previousPosts) {
        const presentPost = state.current.entities[previousPost.data.clientId];
        if (presentPost) {
          presentPosts.push(presentPost);
        }

        state.current = postsAdapter.upsertOne(state.current, {
          ...previousPost,
          unsavedChanges: true,
        });
      }

      state.next = [presentPosts, ...state.next];

      state.previous = newPastState;
      return state;
    });
    builder.addCase(redo, (state) => {
      if (state.next.length === 0) {
        return state;
      }

      const [nextPosts, ...newFutureState] = state.next;

      const presentPosts: PostState[] = [];

      for (const nextPost of nextPosts) {
        const presentPost = state.current.entities[nextPost.data.clientId];

        if (presentPost) {
          presentPosts.push(presentPost);
        }

        state.current = postsAdapter.upsertOne(state.current, {
          ...nextPost,
          unsavedChanges: true,
        });
      }

      state.previous = [...state.previous, presentPosts];

      state.next = newFutureState;
      return state;
    });
    builder.addCase(editCollectionPropertiesByCollectionId, (state, action) => {
      for (const key in action.payload.update) {
        state.data[key] = action.payload.update[key];
      }
      state.unsavedChanges = true;
      return state;
    });
    builder.addCase(toggleCollectionAutoSave, (state, action) => {
      const { value } = action.payload;
      if (typeof value !== "undefined") {
        state.data.autoSave = value;
      } else {
        state.data.autoSave = !state.data.autoSave;
      }
    });
    builder.addCase(collectionSavedById, (state, action) => {
      const savedPosts = action.payload.collection.posts;
      state.unsavedChanges = false;
      state.data.id = action.payload.collection.id;

      const postsToAdd: Post[] = [];

      for (const savedPost of savedPosts) {
        const post = state.current.entities[savedPost.clientId];
        if (post) {
          post.unsavedChanges = false;
          post.data.id = savedPost.id;
        } else {
          postsToAdd.push(savedPost);
        }
      }
      if (postsToAdd.length > 0) {
        return onAddPosts(state, postsToAdd);
      }
      return state;
    });
    builder.addDefaultCase((state, action) => {
      const selectedPostId = action.payload?.postId;
      if (!selectedPostId) {
        return state;
      }
      const selectedPost = state.current.entities[selectedPostId];
      if (!selectedPost) {
        return state;
      }
      const nextPostState = getNextPostState(selectedPost, action);
      return handleUpdateCollectionState(state, [nextPostState], action);
    });
  },
});

const handleUpdateCollectionState = (
  state: Draft<CollectionState>,
  nextPostsState: PostState[],
  action: AnyAction
) => {
  const pastPostStates: PostState[] = [];
  const originalState = isDraft(state) ? original(state) : state;
  if (!originalState) {
    return state;
  }
  for (const nextPostState of nextPostsState) {
    const selectedPostState =
      state.current.entities[nextPostState.data.clientId];

    const originalNextPostState = isDraft(nextPostState)
      ? original(nextPostState)
      : nextPostState;

    if (originalNextPostState && selectedPostState) {
      if (selectedPostState !== nextPostState) {
        pastPostStates.push(selectedPostState);
      }
      state.current = postsAdapter.upsertOne(
        state.current,
        originalNextPostState
      );
    }
  }
  if (pastPostStates.length > 0) {
    //  state.previous = [...state.previous, pastPostStates];
  }
  handleDisableHistory(state, action);
  return state;
};

const handleDisableHistory = (
  state: Draft<CollectionState>,
  action: AnyAction
) => {
  if (editPostPropertiesByCollectionIdAndPostId.match(action)) {
    if ("authorId" in action.payload.update) {
      state.previous.forEach((previous) => {
        previous.forEach((post) => {
          if (post.data.clientId === action.payload.postId) {
            post.data["authorId"] = action.payload.update["authorId"];
          }
        });
      });
      state.next.forEach((next) => {
        next.forEach((post) => {
          if (post.data.clientId === action.payload.postId) {
            post.data["authorId"] = action.payload.update["authorId"];
          }
        });
      });
    }
  }
};

export default collectionSlice.reducer;
