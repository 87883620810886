import { createSlice, Draft } from "@reduxjs/toolkit";
import { strippedHtml } from "client-server-shared/utils/text-utils";
import type { Post } from "client-server-shared/types/types";
import {
  addNewContentBlockIfNeeded,
  addNewPostContentVariantByCollectionIdAndPostId,
  changeContentIndexByCollectionIdAndPostId,
  deleteContentIndexByCollectionIdAndPostId,
  Direction,
  editPostConfigByCollectionIdAndPostId,
  editPostMetadataByCollectionIdAndPostId,
  editPostPropertiesByCollectionIdAndPostId,
  featureImageUploadCompleted,
  togglePostGeneratorOpenByCollectionAndPostId,
  updateActiveContentByCollectionIdAndPostId,
  updateContentByCollectionIdAndPostId,
} from "./actions";

export interface PostState {
  unsavedChanges?: boolean;
  data: Post;
  selectedContentIndex: number;
}

const initialState: PostState = {
  data: {
    id: "",
    clientId: "",
  } as Post,
  selectedContentIndex: 0,
};

export const isContentIndexInRange = (
  content: Post["content"],
  index: number
) => {
  const currentContentLength = (content || []).length;

  if (index < 0) {
    return false;
  }

  if (currentContentLength === 0 || currentContentLength < index + 1) {
    return false;
  } else {
    return true;
  }
};

const ensureContentIndexInRange = (
  state: Draft<PostState>,
  newIndex: number
) => {
  if (isContentIndexInRange(state.data.content, newIndex)) {
    return {
      ...state,
      selectedContentIndex: newIndex,
    };
  } else {
    const newContent =
      (state.data.content || []).length === 0
        ? [{ value: "" }]
        : state.data.content || [];

    return {
      ...state,
      data: {
        ...state.data,
        content: newContent,
      },
      selectedContentIndex: newIndex < 0 ? newContent.length - 1 : 0,
    };
  }
};

const postSlice = createSlice({
  name: "post",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      togglePostGeneratorOpenByCollectionAndPostId,
      (state, action) => {
        const nextOpen =
          typeof action.payload.status === "undefined"
            ? !state.data.generatorOpen
            : action.payload.status;
        const updatedState = {
          ...state,
          data: {
            ...state.data,
            generatorOpen: nextOpen,
          },
        };
        return updatedState;
      }
    );
    builder.addCase(editPostConfigByCollectionIdAndPostId, (state, action) => {
      if (action.payload.replace) {
        return {
          ...state,
          data: {
            ...state.data,
            postConfig: action.payload.update,
          },
        };
      }
      const updatedState = {
        ...state,
        data: {
          ...state.data,
          postConfig: {
            ...(state.data.postConfig || {}),
            ...action.payload.update,
          },
        },
        unsavedChanges:
          action.payload.update?.faqs || action.payload.update?.language
            ? true
            : state.unsavedChanges,
      };

      return updatedState;
    });
    builder.addCase(
      editPostMetadataByCollectionIdAndPostId,
      (state, action) => {
        if (action.payload.replace) {
          return {
            ...state,
            data: {
              ...state.data,
              postMetadata: action.payload.update,
              unsavedChanges: true,
            },
          };
        }
        const updatedState = {
          ...state,
          data: {
            ...state.data,
            postMetadata: {
              ...(state.data.postMetadata || {}),
              ...action.payload.update,
            },
          },
          unsavedChanges: true,
        };

        return updatedState;
      }
    );
    builder.addCase(
      editPostPropertiesByCollectionIdAndPostId,
      (state, action) => {
        const updatedState = {
          ...state,
          data: {
            ...state.data,
            ...action.payload.update,
          },
          unsavedChanges: true,
        };

        return ensureContentIndexInRange(
          updatedState,
          state.selectedContentIndex
        );
      }
    );
    builder.addCase(addNewContentBlockIfNeeded, (state, action) => {
      const { amount } = action.payload;

      const contentBlocks = state.data.content || [];
      const firstContentBlock: { value: string } | undefined = contentBlocks[0];
      let realAmount = amount;
      if (firstContentBlock) {
        if (!strippedHtml(firstContentBlock.value.trim())) {
          realAmount = realAmount - 1;
        }
      }

      if (realAmount > 0) {
        const newContentBlocks = Array(realAmount).fill({
          value: "",
        });

        const updatedState = {
          ...state,
          data: {
            ...state.data,
            content: [...newContentBlocks, ...contentBlocks],
          },
        };

        return updatedState;
      }

      return state;
    });
    builder.addCase(
      deleteContentIndexByCollectionIdAndPostId,
      (state, action) => {
        const contentBlocks = state.data.content || [];
        if (contentBlocks.length - 1 < 1) {
          return state;
        }
        const { index } = action.payload;
        const copyContent = [...contentBlocks];
        copyContent.splice(index, 1);

        const nextIndex = index - 1;
        const updatedState = {
          ...state,
          data: {
            ...state.data,
            content: copyContent,
          },
          unsavedChanges: true,
        };
        return ensureContentIndexInRange(updatedState, nextIndex);
      }
    );
    builder.addCase(updateContentByCollectionIdAndPostId, (state, action) => {
      const { content, index } = action.payload;

      const contentBlocks = state.data.content || [];

      if (contentBlocks.length === 0 || contentBlocks.length < index + 1) {
        return state;
      }
      const copyContent = [...contentBlocks];
      copyContent[index] = {
        value: content,
      };
      return {
        ...state,
        data: {
          ...state.data,
          content: copyContent,
        },
        unsavedChanges: true,
      };
    });
    builder.addCase(
      updateActiveContentByCollectionIdAndPostId,
      (state, action) => {
        const updatedState = ensureContentIndexInRange(
          state,
          state.selectedContentIndex
        );

        const selectedIndex = updatedState.selectedContentIndex;
        const content = updatedState.data.content || [];

        if (isContentIndexInRange(updatedState.data.content, selectedIndex)) {
          const copyContent = [...content];
          if (!copyContent[selectedIndex]?.value) {
            copyContent[selectedIndex] = { value: "" };
          } else {
            if (copyContent[selectedIndex].value === action.payload.content) {
              return state;
            }
          }
          copyContent[selectedIndex].value = action.payload.content;

          return {
            ...updatedState,
            data: {
              ...updatedState.data,
              content: copyContent,
            },
            unsavedChanges: true,
          };
        }
        return updatedState;
      }
    );
    builder.addCase(
      addNewPostContentVariantByCollectionIdAndPostId,
      (state) => {
        return {
          ...state,
          selectedContentIndex: 0,
          data: {
            ...state.data,
            content: [{ value: "" }, ...(state.data.content || [])],
          },
        };
      }
    );
    builder.addCase(
      changeContentIndexByCollectionIdAndPostId,
      (state, action) => {
        const newIndex = action.payload.newIndex;

        if (typeof newIndex === "number") {
          return ensureContentIndexInRange(state, newIndex);
        }
        const direction = action.payload.direction;
        const currentIndex = state.selectedContentIndex;

        if (typeof direction !== "undefined") {
          if (direction === Direction.Forward) {
            const nextIndex = currentIndex + 1;
            return ensureContentIndexInRange(state, nextIndex);
          } else if (direction === Direction.Backward) {
            const nextIndex = currentIndex - 1;
            return ensureContentIndexInRange(state, nextIndex);
          }
        }

        return state;
      }
    );
    builder.addCase(featureImageUploadCompleted, (state, action) => {
      const originalImageUrl = action.payload.originalImageUrl || undefined;
      const currentImageUrl = state.data.featureImage?.url || undefined;

      if (originalImageUrl === currentImageUrl) {
        return {
          ...state,
          data: {
            ...state.data,
            featureImage: {
              ...(state.data.featureImage || {}),
              url: action.payload.url,
            },
          },
          unsavedChanges: true,
        };
      }
      return state;
    });
  },
});

export default postSlice.reducer;
