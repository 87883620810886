import React from "react";

import MUIListSubheader, {
  ListSubheaderProps,
} from "@mui/material/ListSubheader";
import { styled } from "@mui/material/styles";

import { getStyleProps, passProps } from "modules/themes/styleUtils";
import MuiListItem, { ListItemProps } from "@mui/material/ListItem";
import MuiList, { ListProps } from "@mui/material/List";

import MuiListItemText, { ListItemTextProps } from "@mui/material/ListItemText";

const StyledMUIListSubheader = styled(MUIListSubheader)(({ theme }) => {
  return {
    color: theme.palette.text.listItem,
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "30px",
  };
});

const StyledMuiListItem = styled(MuiListItem)(({ theme }) => {
  return {
    color: theme.palette.text.listItem,
  };
});

const StyledMuiListItemText = styled(MuiListItemText)(({ theme }) => ({
  "& > *": { color: theme.palette.text.listItem, fontSize: "14px" },
}));

export const List = React.forwardRef((props: ListProps, ref) => {
  const { children, ...rest } = props;
  return (
    <MuiList ref={ref} {...passProps(rest)} {...getStyleProps(rest)}>
      {children}
    </MuiList>
  );
});
List.displayName = "List";

export const ListSubHeader = React.forwardRef(
  (props: ListSubheaderProps, ref) => {
    const { children, ...rest } = props;
    return (
      <StyledMUIListSubheader
        ref={ref}
        {...passProps(rest)}
        {...getStyleProps(rest)}
      >
        {children}
      </StyledMUIListSubheader>
    );
  }
);
ListSubHeader.displayName = "ListSubHeader";

export { ListItemProps };
export const ListItem = React.forwardRef((props: ListItemProps, ref) => {
  const { children, ...rest } = props;

  return (
    <StyledMuiListItem ref={ref} {...passProps(rest)} {...getStyleProps(rest)}>
      {children}
    </StyledMuiListItem>
  );
});
ListItem.displayName = "ListItem";

export const ListItemText = React.forwardRef(
  (props: ListItemTextProps, ref) => {
    return (
      <StyledMuiListItemText
        ref={ref}
        {...props}
        primaryTypographyProps={{
          sx: {
            ...(props.primaryTypographyProps?.sx || {}),
            fontSize: "14px",
          },
        }}
        {...getStyleProps(props)}
      >
        {props.children}
      </StyledMuiListItemText>
    );
  }
);

ListItemText.displayName = "ListItemText";
