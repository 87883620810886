import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ContentfulImportStep, ImportType } from "./types";

interface ImportSettings {
  type?: ImportType;
  currentStep?: ContentfulImportStep;
}

interface Import {
  settings: ImportSettings | null;
}

const initialState: Import = {
  settings: null,
};

export const settingsSlice = createSlice({
  name: "import",
  initialState,
  reducers: {
    updateImportSettings: (
      state,
      action: PayloadAction<Partial<ImportSettings>>
    ) => {
      state.settings = { ...(state.settings || {}), ...action.payload };
      return state;
    },
    closeImport: (state) => {
      state.settings = null;
    },
  },
});

export const { updateImportSettings, closeImport } = settingsSlice.actions;

export default settingsSlice.reducer;
