import * as React from "react";

export function useMounted() {
  const mounted = React.useRef(false);

  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const safeRun = React.useCallback(
    (func: () => void) => {
      if (mounted.current) {
        func();
      }
    },
    [mounted]
  );

  const safeRunAsync = React.useCallback(
    async (func: () => Promise<void>) => {
      if (mounted.current) {
        await func();
      }
    },
    [mounted]
  );

  return { mounted, safeRun, safeRunAsync };
}
