import { getStyleProps } from "modules/themes/styleUtils";
import MuiCircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import React from "react";

export const CircularProgress = React.forwardRef(
  (props: CircularProgressProps, ref) => {
    const sx = getStyleProps(props).sx;
    return (
      <MuiCircularProgress
        ref={ref}
        {...props}
        sx={{
          color: "#ADD8E6",
          ...sx,
        }}
      />
    );
  }
);

CircularProgress.displayName = "CircularProgress";
