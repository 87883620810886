import Link from "next/link";
import React from "react";

export const NextLink = React.forwardRef((props: any, ref) => {
  const { children, ...rest } = props;
  return (
    <Link {...rest} ref={ref}>
      {children}
    </Link>
  );
});
