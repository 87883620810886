import {
  getPlanByPriceId,
  PlanName,
  creatorPlanPriceIds,
  unlimitedPlanPriceIds,
  proPlanPriceIds,
  legacyPlanPriceIds,
  allYearlyPriceIds,
  legacyCreatorPlanPriceIds,
  appSumoPlanIds,
} from "client-server-shared/pricing/v2";
import { SubscriptionWithPrice } from "client-server-shared/types/payment";
import type { UserDetailsWithSubscriptions } from "../types/auth-type";
import { dayjs } from "./dayjs";

export const getUserHasAnySubscriptions = (
  subscriptions?: SubscriptionWithPrice[]
) => Array.isArray(subscriptions) && subscriptions.length > 0;

export const getUserHasActiveSubscriptions = (
  subscriptions?: SubscriptionWithPrice[]
) =>
  Array.isArray(subscriptions) &&
  subscriptions.some(
    (subscription) =>
      subscription.status === "active" || subscription.status === "trialing"
  );

const emptyArry: SubscriptionWithPrice[] = [];

export const getUserActiveSubscriptions = (
  subscriptions?: SubscriptionWithPrice[]
) => {
  return !subscriptions
    ? emptyArry
    : subscriptions.filter(
        (subscription) =>
          subscription.status === "active" || subscription.status === "trialing"
      );
};

export const isUserCanceledSubscription = (
  subscriptions?: SubscriptionWithPrice[]
) => {
  if (!subscriptions || subscriptions.length === 0) {
    return false;
  }
  return subscriptions.some(
    (subscription) => subscription.status === "canceled"
  );
};

export const getUserCurrentPlan = (
  subscriptions?: SubscriptionWithPrice[]
): SubscriptionWithPrice | null => {
  if (!subscriptions || subscriptions.length === 0) {
    return null;
  }
  const activeSubscriptions = getUserActiveSubscriptions(subscriptions);
  if (!Array.isArray(activeSubscriptions)) {
    return null;
  }
  if (activeSubscriptions.length === 0) {
    return null;
  }
  const mostExpensive = activeSubscriptions.reduce(function (prev, current) {
    const previousPriceId = prev.priceId;
    const previousPlan = getPlanByPriceId(previousPriceId);
    const previousAmount = Number(previousPlan?.unit_amount || 0);

    const currentPriceId = current.priceId;
    const currentPlan = getPlanByPriceId(currentPriceId);
    const currentAmount = Number(currentPlan?.unit_amount || 0);

    if (previousAmount === currentAmount) {
      return prev.credits > current.credits ? prev : current;
    }

    return previousAmount > currentAmount ? prev : current;
  });
  return mostExpensive;
};

export const IsUserInFreeTrial = (
  userDetails: Pick<
    UserDetailsWithSubscriptions,
    "freeTrialEndTime" | "subscriptions"
  >
) => {
  const freeTrialEndTime = userDetails.freeTrialEndTime;
  const ended = dayjs().isAfter(dayjs(freeTrialEndTime));
  const inPaidPlan = getUserInPaidPlan(userDetails.subscriptions);
  if (!ended && !inPaidPlan) {
    return true;
  }
  return false;
};

export const getIfUserCanHavePaidTrial = (
  userDetails: Pick<
    UserDetailsWithSubscriptions,
    "freeTrialEndTime" | "subscriptions" | "paidTrialEnabledAt"
  >
) => {
  const inFreeTrial = IsUserInFreeTrial(userDetails);
  const hasCanceledSubscription = isUserCanceledSubscription(
    userDetails.subscriptions
  );
  const hasActiveSubscription = getUserHasActiveSubscriptions(
    userDetails.subscriptions
  );

  const paidTrialEnabled = userDetails.paidTrialEnabledAt;

  return (
    paidTrialEnabled &&
    !inFreeTrial &&
    !hasCanceledSubscription &&
    !hasActiveSubscription
  );
};

const getPlanNameById = (priceId: string) => {
  const plan = getPlanByPriceId(priceId);
  return plan?.planName;
};

export const isDowngradePlan = (
  newPriceId: string,
  subscriptions: SubscriptionWithPrice[] = [],
  newSubscriptionId: string
) => {
  if (!subscriptions || subscriptions.length === 0) {
    return false;
  }
  const currentSameSubscription = subscriptions.find(
    (s) => s.id === newSubscriptionId
  );
  const currentPlan =
    currentSameSubscription || getUserCurrentPlan(subscriptions);
  const currentPlanName = getPlanNameById(currentPlan?.priceId || "");
  if (!currentPlanName) {
    return false;
  }
  if (currentPlan && currentPlan.id !== newSubscriptionId) {
    return false;
  }
  const currentPlanPrice = getPlanByPriceId(currentPlan.priceId)?.unit_amount;
  const newPlan = getPlanByPriceId(newPriceId);
  if (Number(currentPlanPrice) > Number(newPlan?.unit_amount)) {
    // todos... Changing monthly to yearly is considered a downgrade -> not correct.
    return true;
  }

  return false;
};

export const isCreatorPlanByPriceId = (priceId: string) => {
  return creatorPlanPriceIds.includes(priceId);
};

export const isProPlanByPriceId = (priceId: string) => {
  return proPlanPriceIds.includes(priceId);
};

export const isYearlyPlanByPriceId = (priceId: string) => {
  return allYearlyPriceIds.includes(priceId);
};

export const isUnlimitedPlanByPriceId = (priceId: string) => {
  return unlimitedPlanPriceIds.includes(priceId);
};

export const isUserInAppSumoPlan = (priceId: string) => {
  return appSumoPlanIds.includes(priceId);
};

export const isUpgradeToNewPlan = (
  newPriceId: string,
  subscriptions: SubscriptionWithPrice[] = [],
  newSubscriptionId: string
) => {
  if (!subscriptions || subscriptions.length === 0) {
    return false;
  }
  const currentSameSubscription = subscriptions.find(
    (s) => s.id === newSubscriptionId
  );
  const currentPlan =
    currentSameSubscription || getUserCurrentPlan(subscriptions);
  const currentPlanName = getPlanNameById(currentPlan?.priceId || "");

  if (!currentPlanName) {
    return false;
  }

  if (currentPlan && currentPlan.id !== newSubscriptionId) {
    return false;
  }

  const newPlan = getPlanByPriceId(newPriceId);

  if (
    currentPlan?.priceId &&
    legacyCreatorPlanPriceIds.includes(currentPlan?.priceId) &&
    newPlan?.planName === PlanName.pro
  ) {
    return false;
  }

  const currentPlanPrice = getPlanByPriceId(currentPlan.priceId)?.unit_amount;
  if (Number(currentPlanPrice) < Number(newPlan?.unit_amount)) {
    return true;
  }

  return false;
};

export const getUserInPaidPlan = (subscriptions?: SubscriptionWithPrice[]) => {
  return (
    getUserInActiveUnlimitedPlan(subscriptions) ||
    getUserInActiveCreatorPlan(subscriptions) ||
    getUserInActiveProPlan(subscriptions) ||
    getUserInActiveAppSumoPlan(subscriptions)
  );
};

export const getUserInLegacyPlan = (
  subscriptions?: SubscriptionWithPrice[]
) => {
  return !!(
    Array.isArray(subscriptions) &&
    subscriptions.some(
      (subscription) =>
        (subscription.status === "active" ||
          subscription.status === "trialing") &&
        subscription.priceId &&
        legacyPlanPriceIds.includes(subscription.priceId)
    )
  );
};

export const getUserInActiveUnlimitedPlan = (
  subscriptions?: SubscriptionWithPrice[]
) => {
  return !!(
    Array.isArray(subscriptions) &&
    subscriptions.some(
      (subscription) =>
        (subscription.status === "active" ||
          subscription.status === "trialing") &&
        subscription.priceId &&
        unlimitedPlanPriceIds.includes(subscription.priceId)
    )
  );
};

export const getUserInActiveProPlan = (
  subscriptions?: SubscriptionWithPrice[]
) => {
  return !!(
    Array.isArray(subscriptions) &&
    subscriptions.some(
      (subscription) =>
        (subscription.status === "active" ||
          subscription.status === "trialing") &&
        subscription.priceId &&
        proPlanPriceIds.includes(subscription.priceId)
    )
  );
};
export const getUserInActiveCreatorPlan = (
  subscriptions?: SubscriptionWithPrice[]
) => {
  return !!(
    Array.isArray(subscriptions) &&
    subscriptions.some(
      (subscription) =>
        (subscription.status === "active" ||
          subscription.status === "trialing") &&
        subscription.priceId &&
        creatorPlanPriceIds.includes(subscription.priceId)
    )
  );
};
export const getUserInActiveAppSumoPlan = (
  subscriptions?: SubscriptionWithPrice[]
) => {
  return !!(
    Array.isArray(subscriptions) &&
    subscriptions.some(
      (subscription) =>
        (subscription.status === "active" ||
          subscription.status === "trialing") &&
        subscription.priceId &&
        appSumoPlanIds.includes(subscription.priceId)
    )
  );
};

export type Features = null | undefined | Record<string, boolean>;

export const userHasGPT4Enabled = (features: Features) => {
  return features?.gpt4;
};
