import { createTheme } from "@mui/material/styles";
import breakpoints from "./base/breakpoints";
import colors from "./base/colors";
import typography from "./base/typography";
import boxShadows from "./base/boxShadows";
import borders from "./base/borders";
import boxShadow from "./functions/boxShadow";
import linearGradient from "./functions/linearGradient";
import pxToRem from "./functions/pxToRem";
import rgba from "./functions/rgba";
import globals from "./base/globals";

export const lightTheme = createTheme({
  breakpoints: { ...breakpoints },
  palette: {
    ...colors,
  },
  typography: {
    ...typography,
  },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  functions: {
    boxShadow: boxShadow,
    linearGradient,
    pxToRem,
    rgba,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
      },
    },
  },
});
