import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type {
  SessionType,
  ClientUserDetails,
} from "client-server-shared/types/auth-type";
import type { SubscriptionWithPrice } from "client-server-shared/types/payment";
import type { Notification } from "client-server-shared/types/notifications";
import { hydrate } from "../actions";
import { IntegrationServer } from "client-server-shared/types/types";

interface UserState {
  session: SessionType | null;
  userDetails: ClientUserDetails | null;
  activePlan: SubscriptionWithPrice | null;
  notifications: Notification[] | null;
  integrations: IntegrationServer[] | null;
}

const initialUserState: UserState = {
  session: null,
  userDetails: null,
  activePlan: null,
  notifications: null,
  integrations: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setSession: (state, action: PayloadAction<SessionType | null>) => {
      state.session = action.payload;
    },
    setUserDetails: (
      state,
      action: PayloadAction<ClientUserDetails | null>
    ) => {
      if (state.userDetails && action.payload) {
        if (state.userDetails.onboardingOption) {
          const option = state.userDetails.onboardingOption;
          state.userDetails = {
            ...state.userDetails,
            ...action.payload,
            onboardingOption: option,
          };
          return;
        }
      }
      state.userDetails = action.payload;
    },
    updateUserDetails: (
      state,
      action: PayloadAction<Partial<ClientUserDetails>>
    ) => {
      if (state.userDetails) {
        state.userDetails = {
          ...state.userDetails,
          ...action.payload,
        };
      }
    },
    contentfulAuthSucceeded: (state) => {
      if (state.userDetails) {
        state.userDetails.encrypted_contentful_access_token = true;
      }
    },
    wordpressAuthSucceeded: (state) => {
      if (state.userDetails) {
        state.userDetails.encrypted_wordpress_access_token = true;
      }
    },
    setSubscription: (
      state,
      action: PayloadAction<SubscriptionWithPrice | null>
    ) => {
      state.activePlan = action.payload;
    },
    setNotifications: (state, action: PayloadAction<Notification[] | null>) => {
      state.notifications = action.payload;
    },
    markNotificationAsSeen: (state, action: PayloadAction<string>) => {
      if (state.notifications) {
        state.notifications = state.notifications.filter(
          (n) => n.id !== action.payload
        );
      }
    },
    setIntegrations: (state, action: PayloadAction<IntegrationServer[]>) => {
      state.integrations = action.payload;
    },
    integrationDeleted: (state, action: PayloadAction<string>) => {
      if (state.integrations) {
        state.integrations = state.integrations.filter(
          (i) => i.id !== action.payload
        );
      }
    },
    integrationAdded: (state, action: PayloadAction<IntegrationServer>) => {
      if (state.integrations) {
        state.integrations = [...state.integrations, action.payload];
      } else {
        state.integrations = [action.payload];
      }
    },
    integrationModified: (state, action: PayloadAction<IntegrationServer>) => {
      if (state.integrations) {
        state.integrations = state.integrations.map((i) => {
          if (i.id === action.payload.id) {
            return action.payload;
          }
          return i;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (
        state.session?.user?.id ||
        state.userDetails ||
        state.activePlan ||
        state.notifications ||
        state.integrations
      ) {
        return state;
      }
      return action.payload.user;
    });
  },
});

export const {
  updateUserDetails,
  setSession,
  setSubscription,
  contentfulAuthSucceeded,
  setUserDetails,
  wordpressAuthSucceeded,
  setNotifications,
  markNotificationAsSeen,
  setIntegrations,
  integrationDeleted,
  integrationAdded,
  integrationModified,
} = userSlice.actions;

export default userSlice.reducer;
