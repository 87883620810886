import React from "react";

import MuiButton, { ButtonProps } from "@mui/material/Button";
import MuiIconButton, { MuiIconButtonProps } from "@mui/material/IconButton";

import { styled } from "@mui/material/styles";

import { getStyleProps, passProps } from "modules/themes/styleUtils";
import { CSSProperties } from "react";

export interface CustomButtonProps extends ButtonProps {}

export { ButtonProps };

export type IconButtonProps = MuiIconButtonProps;

const StyledMuiButton = styled<CustomButtonProps>(MuiButton, {
  shouldForwardProp: (props: string) => props !== "backgroundColor",
})(({ backgroundColor, theme }) => {
  const customStyle: CSSProperties = {};
  if (backgroundColor === "secondary") {
    customStyle["backgroundColor"] = theme.palette.background["secondary"];
    customStyle["color"] = theme.palette.text["secondary"];
    customStyle["&:hover"] = {
      backgroundColor: theme.palette.background["secondaryFocus"],
    };
  } else if (backgroundColor === "softPrimary") {
    customStyle["backgroundColor"] = theme.palette.background["softPrimary"];
    customStyle["color"] = theme.palette.text["softPrimary"];
    customStyle["&:hover"] = {
      backgroundColor: theme.palette.background["softPrimaryFocus"],
    };
  }
  return {
    borderRadius: "8px",
    fontSize: "12px",
    ...customStyle,
  };
});

export const Button = React.forwardRef((props: CustomButtonProps, ref) => {
  const { children, loading, ...rest } = props;
  return (
    <StyledMuiButton
      backgroundColor={props.backgroundColor}
      ref={ref}
      {...passProps(rest)}
      {...getStyleProps(rest)}
    >
      {children}
    </StyledMuiButton>
  );
});
Button.displayName = "Button";

export const InlineButton = React.forwardRef(
  (props: CustomButtonProps, ref) => {
    const { children, sx = {}, ...rest } = props;

    return (
      <Button
        capitalize
        ref={ref}
        withUnderline
        {...passProps(rest)}
        {...getStyleProps(rest)}
        sx={{
          ...sx,
          fontSize: "inherit",
          minWidth: "initial",
        }}
        padding={0}
      >
        {children}
      </Button>
    );
  }
);

export const IconButton = React.forwardRef((props: IconButtonProps, ref) => {
  const { children, ...rest } = props;

  return (
    <MuiIconButton ref={ref} {...passProps(rest)} {...getStyleProps(rest)}>
      {children}
    </MuiIconButton>
  );
});

IconButton.displayName = "IconButton";

export const LinkButton = React.forwardRef((props: ButtonProps, ref) => {
  const { children, ...rest } = props;

  return (
    <Button
      ref={ref}
      variant="text"
      disableElevation
      disableFocusRipple
      size="small"
      color="info"
      disableRipple
      {...passProps(rest)}
      sx={{
        lineHeight: "inherit",
        padding: 0,
        minWidth: "initial",
        textDecoration: "underline",
      }}
    >
      {children}
    </Button>
  );
});

LinkButton.displayName = "LinkButton";

export const ToggleButton = React.forwardRef(
  (props: ToggleButtonProps, ref) => {
    const { children, ...rest } = props;
    return (
      <MuiToggleButton ref={ref} {...passProps(rest)} {...getStyleProps(rest)}>
        {children}
      </MuiToggleButton>
    );
  }
);
ToggleButton.displayName = "ToggleButton";

export const BlackButton = React.forwardRef((props: CustomButtonProps, ref) => {
  const { children, ...rest } = props;

  return (
    <Button
      capitalize
      {...rest}
      sx={{
        background: "#111827",
        color: "text.white",
        border: 0,
        borderRadius: "22px",
        textDecoration: "none",
        "&:hover": {
          background: "#111827",
          opacity: 0.8,
          border: 0,
        },
        ...(rest.sx || {}),
      }}
      ref={ref}
    >
      {children}
    </Button>
  );
});
