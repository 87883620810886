import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
  original,
} from "@reduxjs/toolkit";
import { hydrate } from "../actions";

import {
  addCollections,
  collectionsLoaded,
  removeCollectionById,
} from "./actions";

import collectionReducer, {
  loadCollectionState,
  CollectionState,
} from "./collection";
import { folderDeleted } from "reducers/folders/action";

export const collectionsAdapter = createEntityAdapter<CollectionState>({
  selectId: (entity) => entity.data.clientId,
  sortComparer: (a, b) =>
    new Date(b.data.updatedAt || b.data.createdAt).getTime() -
    new Date(a.data.updatedAt || a.data.createdAt).getTime(),
});

const collectionsSlice = createSlice({
  name: "collections",
  initialState: collectionsAdapter.getInitialState(),
  reducers: {
    onCollectionFolderMoved: (
      state,
      action: PayloadAction<{
        ids: string[];
        folderId?: string;
      }>
    ) => {
      const { ids } = action.payload;
      for (const id of ids) {
        const collection = state.entities[id];
        if (collection) {
          collection.data.folder_id = action.payload.folderId;
        }
      }

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (state.ids.length > 0) {
        return state;
      }
      return action.payload.collections;
    });
    builder.addCase(collectionsLoaded, (state, action) => {
      const collections = action.payload;
      const collectionsToAdd = collections.map((collectionToAdd) =>
        loadCollectionState(collectionToAdd)
      );
      const newState = collectionsAdapter.upsertMany(state, collectionsToAdd);
      return newState;
    });
    builder.addCase(removeCollectionById, (state, action) => {
      const newState = collectionsAdapter.removeOne(
        state,
        action.payload.collectionId
      );
      return newState;
    });
    builder.addCase(folderDeleted, (state, action) => {
      const matchCollections = Object.values(state.entities).filter(
        (entity) => {
          return entity && entity.data.folder_id === action.payload;
        }
      );
      if (matchCollections.length === 0) {
        return state;
      }
      return collectionsAdapter.removeMany(
        state,
        matchCollections
          .map((collection) => collection?.data.clientId)
          .filter(Boolean)
      );
    });
    builder.addCase(addCollections, (state, action) => {
      const collections = action.payload;
      const collectionsToAdd = collections.map((collectionToAdd) =>
        loadCollectionState(collectionToAdd)
      );
      const newState = collectionsAdapter.upsertMany(state, collectionsToAdd);
      return newState;
    });
    builder.addDefaultCase((state, action) => {
      const selectedCollectionId = action.payload?.collectionId;
      if (!selectedCollectionId) {
        return state;
      }
      const selectedCollection = state.entities[selectedCollectionId];
      if (!selectedCollection) {
        return state;
      }
      state.entities[selectedCollectionId] = collectionReducer(
        selectedCollection,
        action
      );
      return state;
    });
  },
});

export const { onCollectionFolderMoved } = collectionsSlice.actions;

export default collectionsSlice.reducer;
