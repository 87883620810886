import { Box } from "components/ui/box";
import { useRouter } from "next/router";
import {
  isBlogRoute,
  isLoginRoute,
  isTemplateRoute,
  isToolRoute,
  isValidServerRoute,
} from "client-server-shared/utils/get-url";
import Footer from "./footer";
import Header from "./common-header";
import { useLoadSession } from "hooks/use-user";
import React from "react";
import dynamic from "next/dynamic";
import { BlackFridayBanner } from "modules/marketing-pages/banners/black-friday";

const ChatBot = dynamic(() => import("modules/chatbot/chatbot"), {
  ssr: false,
});

const PopupBanner = dynamic(() => import("../marketing-pages/banner"), {
  ssr: false,
});
const MarktingPopup = dynamic(() => import("./marketing-popup"), {
  ssr: false,
});

const InnerWrapper = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const { loadSessionOnce } = useLoadSession();
    const router = useRouter();

    React.useEffect(() => {
      loadSessionOnce();
    }, []);

    const toolRoute = isToolRoute(router.pathname);
    const blogRoute = isBlogRoute(router.pathname);
    const tmplateRoute = isTemplateRoute(router.pathname);
    return (
      <Box>
        <BlackFridayBanner />
        {
          /*
          <PopupBanner />
          */
        }
        <Header />
        <Box component="main">{children}</Box>

        {toolRoute || blogRoute || tmplateRoute ? <MarktingPopup /> : null}
        <Footer />
      </Box>
    );
  }
);

const NonProtectedLayout = React.memo(
  ({ children }: { children: React.ReactNode }) => {
    const router = useRouter();
    if (!isValidServerRoute(router.pathname) || isLoginRoute(router.pathname)) {
      return <>{children}</>;
    }
    return <InnerWrapper>{children}</InnerWrapper>;
  }
);

export default NonProtectedLayout;
