import { isLoading } from "hooks/use-loading";
import { processingLogAdapter } from "reducers/processing-log";
import { RootState } from "reducers/types";

const processingLogSelectors = processingLogAdapter.getSelectors();

export const selectProcessingLogById = (state: RootState, id?: string) => {
  return !id
    ? null
    : processingLogSelectors.selectById(state.processingLogs, id);
};

export const getCollectionSaveIdPrefix = (id: string) => `${id}-saving`;

export const selectIsCollectionBeingSavedById = (
  state: RootState,
  id: string
) => {
  const status = processingLogSelectors.selectById(
    state.processingLogs,
    getCollectionSaveIdPrefix(id)
  )?.status;
  return status && isLoading(status);
};
