import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { v4 } from "uuid";

type Notification = {
  id: string;
  type: "success" | "info" | "warning" | "error";
  message: string;
  title?: string;
  actionText?: string;
  action?: () => void;
  hideOnClickaway?: boolean;
};

type NotificationsState = Notification[];

const initialState: NotificationsState = [];

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    addNotification: (
      state,
      action: PayloadAction<Omit<Notification, "id">>
    ) => {
      const newNotification = {
        id: v4(),
        ...action.payload,
      };
      state.push(newNotification);
      return state;
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      return state.filter((notification) => notification.id !== action.payload);
    },
  },
});

export const { addNotification, removeNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
