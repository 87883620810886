import { isAnyOf, AnyAction, createAction } from "@reduxjs/toolkit";
import type {
  Post,
  Collection,
  SavedCollection,
} from "client-server-shared/types/types";

export const emptyAction = {} as AnyAction; // only use to create an initial state

interface Meta {
  autoSave?: boolean;
  navigateOnCreate?: boolean;
}

export const addPosts = createAction(
  "add_posts",
  (
    {
      collectionId,
      posts,
    }: {
      collectionId: string;
      posts: Post[];
    },
    meta: Meta = {}
  ) => {
    return {
      payload: { collectionId, posts },
      meta,
    };
  }
);

export const removePostById = createAction<{
  collectionId: string;
  postId: string;
}>("remove_post_by_id");

export const removeCollectionById = createAction<{
  collectionId: string;
}>("remove_collection_by_id");

export const addCollections = createAction(
  "add_collections",
  (collections: Collection[], meta: Meta = {}) => {
    return {
      payload: collections,
      meta,
    };
  }
);
export const loadCollections =
  createAction<SavedCollection[]>("load_collections");
export const collectionsLoaded =
  createAction<Collection[]>("collections_loaded");

export const editPostPropertiesByCollectionIdAndPostId = createAction<{
  update: Partial<Omit<Post, "id">>;
  postId: string;
  collectionId: string;
}>("edit_post_by_collection_id_and_post_id");

export const editPostConfigByCollectionIdAndPostId = createAction<{
  update: Partial<Post["postConfig"]>;
  postId: string;
  collectionId: string;
  replace?: boolean;
}>("edit_post_config_by_collection_id_and_post_id");

export const editPostMetadataByCollectionIdAndPostId = createAction<{
  update: Partial<Post["postMetadata"]>;
  postId: string;
  collectionId: string;
  replace?: boolean;
}>("edit_post_metadata_by_collection_id_and_post_id");

export const editCollectionPropertiesByCollectionId = createAction<{
  collectionId: string;
  update: Partial<{ title: string; templateId: string, metaData: Collection['metaData'] }>;
}>("edit_collection_by_collection_id");

export const undo = createAction<{
  collectionId: string;
}>("undo");

export const redo = createAction<{
  collectionId: string;
}>("redo");

export enum Direction {
  Forward = "forward",
  Backward = "backward",
}

export const changeContentIndexByCollectionIdAndPostId = createAction<{
  newIndex?: number;
  direction?: Direction;
  postId: string;
  collectionId: string;
}>("change_content_index_by_collection_id_and_post_id");

export const addNewPostContentVariantByCollectionIdAndPostId = createAction<{
  postId: string;
  collectionId: string;
}>("add_new_post_content_variant_by_collection_id_and_post_id");

export const updateActiveContentByCollectionIdAndPostId = createAction<{
  content: string;
  postId: string;
  collectionId: string;
}>("update_active_content_by_collection_id_and_post_id");

export const updateContentByCollectionIdAndPostId = createAction<{
  content: string;
  postId: string;
  collectionId: string;
  index: number;
}>("update_content_by_index_by_collection_id_and_post_id");

export const togglePostGeneratorOpenByCollectionAndPostId = createAction<{
  collectionId: string;
  postId: string;
  status?: boolean;
}>("toggle_post_generator_open_by_collection_id_and_post_id");

export const deleteContentIndexByCollectionIdAndPostId = createAction<{
  postId: string;
  collectionId: string;
  index: number;
}>("delete_content_index_by_collection_id_and_post_id");

export const addNewContentBlockIfNeeded = createAction<{
  postId: string;
  collectionId: string;
  amount: number;
}>("add_content_block_if_needed_by_index_by_collection_id_and_post_id");

export const saveCollectionById = createAction<{
  collectionId: string;
  force?: boolean;
}>("save_collection_by_id");

export const collectionSavedById = createAction<{
  collectionId: string;
  collection: SavedCollection;
}>("collection_saved_by_id");

export const isUserEditCollectionOrPostsAction = (action: AnyAction) =>
  isAnyOf(
    editPostConfigByCollectionIdAndPostId,
    editPostMetadataByCollectionIdAndPostId,
    editCollectionPropertiesByCollectionId,
    undo,
    redo,
    editPostPropertiesByCollectionIdAndPostId,
    featureImageUploadCompleted,
    updateActiveContentByCollectionIdAndPostId,
    updateContentByCollectionIdAndPostId,
    deleteContentIndexByCollectionIdAndPostId
  )(action);

export const featureImageUploadCompleted = createAction<{
  postId: string;
  collectionId: string;
  originalImageUrl?: string;
  url: string;
}>("feature_image_upload_completed");

export const toggleCollectionAutoSave = createAction<{
  collectionId: string;
  value?: boolean;
}>("toggle_collection_auto_save");
