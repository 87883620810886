let tracker: Awaited<typeof import("@vercel/analytics").default> | null = null;

const getTracker = () => {
  if (typeof window === "undefined") {
    return {
      track: () => {},
    };
  }
  tracker = window.umami;
  if (!tracker) {
    return {
      track: () => {},
    };
  }
  return tracker;
};
type Tracker = Awaited<typeof import("@vercel/analytics").default>;

export const track = async (...args: Parameters<Tracker["track"]>) => {
  try {
    const tracker = getTracker();
    tracker.track(...args);
  } catch (e) {}
};

export const sendEvent = async (...args: Parameters<Tracker["track"]>) => {
  try {
    const tracker = getTracker();
    tracker.track(...args);
  } catch (e) {}
};

const tracked: Record<string, number> = {};

export const trackWithLimitedTime = async (
  eventName: string,
  properties?: Record<string, any>
) => {
  const identifier = properties
    ? `${eventName}-${JSON.stringify(properties)}`
    : eventName;
  if (tracked[identifier]) {
    tracked[identifier] += 1;
    return;
  }
  tracked[identifier] = 1;
  await track(eventName, properties);
};
