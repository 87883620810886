import React from "react";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";
import { absoluteCenter } from "modules/themes/styleUtils";
import { mainPageWidth } from "src/constants";

interface Props {
  title?: string;
  onRetry?: () => void;
  boxProps?: BoxProps;
}

export const ErrorLayout = ({ title, onRetry, boxProps = {} }: Props) => {
  return (
    <Box
      {...boxProps}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...(boxProps.sx || {}),
      }}
    >
      <Typography sx={{ p: "8px 0" }} variant="body1">
        {title || "An unexpected error has occurred"}
      </Typography>
      <Typography variant="body2" sx={{ pb: 3 }}>
        Please double check your internet connection and try again.
      </Typography>
      {onRetry && (
        <Button
          onClick={onRetry}
          color="secondary"
          variant="contained"
          disableElevation
        >
          Retry
        </Button>
      )}
    </Box>
  );
};

export const ErrorLayoutAbsoluteCenter = (
  props: Props & { inMainPage: boolean }
) => {
  return (
    <ErrorLayout
      {...props}
      boxProps={{
        sx: {
          ...absoluteCenter,
          width: props.inMainPage ? mainPageWidth : "100%",
        },
      }}
    />
  );
};
