interface Conversation {
  role: "user" | "system" | "assistant";
  content: string;
}
export const ensureSequence = (conversation: Conversation[]) => {
  return conversation.reduce((acc: Conversation[], item, index) => {
    if (index === 0) {
      acc.push(item);
      return acc;
    }

    const prevItem = conversation[index - 1];
    if (item.role !== prevItem.role) {
      acc.push(item);
    } else {
      acc[acc.length - 1] = item;
    }

    return acc;
  }, []);
};
