import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { hydrate } from "../actions";
import { LoadingStatus, ProcessingLog } from "./types";

export const processingLogAdapter = createEntityAdapter<ProcessingLog>({
  selectId: (log) => log.id,
});

export const processingLogSlice = createSlice({
  name: "processingLog",
  initialState: processingLogAdapter.getInitialState(),
  reducers: {
    processingLogsAdded: processingLogAdapter.upsertMany,
    processingStatusChange: (
      state,
      action: PayloadAction<{ id: string; status: LoadingStatus }>
    ) => {
      const entity = state.entities[action.payload.id];
      if (entity) {
        entity.status = action.payload.status;
      } else {
        return processingLogAdapter.addOne(state, {
          id: action.payload.id,
          status: action.payload.status,
        });
      }
      return state;
    },
    clearProcessingLog: () => {
      return processingLogAdapter.getInitialState();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hydrate, (state, action) => {
      if (state.ids.length > 0) {
        return state;
      }
      return action.payload.processingLogs;
    });
  },
});

export const {
  processingLogsAdded,
  processingStatusChange,
  clearProcessingLog,
} = processingLogSlice.actions;

export default processingLogSlice.reducer;
