import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import isEqual from "lodash/isEqual";

type CategoriesState = {
  list: string[];
  latestList: string[];
  unsavedChanges: boolean;
};

const initialState: CategoriesState = {
  list: [],
  latestList: [],
  unsavedChanges: false,
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    categoriesLoaded: (state, action: PayloadAction<string[]>) => {
      state.list = action.payload;
      state.latestList = action.payload;
    },
    addCategories: (state, action: PayloadAction<string[]>) => {
      state.latestList = [...new Set([...state.latestList, ...action.payload])];
    //  state.unsavedChanges = !isEqual(state.list, state.latestList);
    },
    removeCategories: (state, action: PayloadAction<string[]>) => {
      state.latestList = state.latestList.filter(
        (category) => !action.payload.includes(category)
      );
    //  state.unsavedChanges = !isEqual(state.list, state.latestList);
    },
    categoriesSaved: (state, action: PayloadAction<string[]>) => {
      state.latestList = [...new Set([...state.latestList, ...action.payload])];
      state.list = state.latestList;
      state.unsavedChanges = false;
    },
  },
});

export const {
  categoriesLoaded,
  categoriesSaved,
  addCategories,
  removeCategories,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
