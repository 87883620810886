import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import { getStyleProps, passProps } from "modules/themes/styleUtils";
import MuiToolbar, { ToolbarProps } from "@mui/material/Toolbar";
import React from "react";

export const AppBar = React.forwardRef((props: AppBarProps, ref) => {
  const { children, ...rest } = props;
  return (
    <MuiAppBar {...passProps(rest)} ref={ref} {...getStyleProps(rest)}>
      {children}
    </MuiAppBar>
  );
});

AppBar.displayName = "AppBar";

export const Toolbar = (props: ToolbarProps) => {
  const { children, ...rest } = props;

  return (
    <MuiToolbar {...passProps(rest)} {...getStyleProps(rest)}>
      {children}
    </MuiToolbar>
  );
};
